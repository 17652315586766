import React from 'react'
import {Helmet} from "react-helmet";

const Layout = ({title, col, children}) => {
  return (
    <div className={`flex flex-grow text-center mx-auto w-full`}>
    <Helmet>
      <title>{title || "PetsterGaming App"}</title>
    </Helmet>
        <div className={`flex ${col ? "flex-col" : ""} flex-grow text-white backdrop-filter backdrop-blur-md bg-black bg-opacity-40 p-4 rounded-lg`}>
            {children}
        </div>
    </div>
  )
}

export default Layout