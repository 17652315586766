import React from 'react';

const BlogPost = props => {
    return (
        <div className={'flex flex-col'}>
            <h1 className={'text-2xl underline'}>{props.title} - {props.update}</h1>
            <div className={'pl-4'} dangerouslySetInnerHTML={{__html: props.text}}></div>
        </div>
    );
};

export default BlogPost;