import React from 'react'
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const Footer = () => {
    const year = new Date().getFullYear();

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

  return (
    <footer>
        <p className='font-medium bg-black backdrop-filter backdrop-blur-md bg-opacity-50 text-white text-center p-2'>
            <a href={'https://portfolio.petstergaming.com'} rel="noreferrer" target={'_blank'}>Jason Palmeri</a> &copy; {year} {userInfo && (<span>| <Link to={'/settings'}>Settings</Link></span>)}
        </p>
    </footer>
  )
}

export default Footer