export const NOTEPAD_GET_REQUEST = "NOTEPAD_GET_REQUEST";
export const NOTEPAD_GET_SUCCESS = "NOTEPAD_GET_SUCCESS";
export const NOTEPAD_GET_FAIL = "NOTEPAD_GET_FAIL";

export const NOTEPAD_UPDATE_REQUEST = "NOTEPAD_UPDATE_REQUEST";
export const NOTEPAD_UPDATE_SUCCESS = "NOTEPAD_UPDATE_SUCCESS";
export const NOTEPAD_UPDATE_FAIL = "NOTEPAD_UPDATE_FAIL";
export const NOTEPAD_UPDATE_ACKNOWLEDGE = "NOTEPAD_UPDATE_ACKNOWLEDGE";

export const NOTEPAD_CREATE_REQUEST = "NOTEPAD_CREATE_REQUEST";
export const NOTEPAD_CREATE_SUCCESS = "NOTEPAD_CREATE_SUCCESS";
export const NOTEPAD_CREATE_FAIL = "NOTEPAD_CREATE_FAIL";
export const NOTEPAD_CREATE_ACKNOWLEDGE = "NOTEPAD_CREATE_ACKNOWLEDGE";

export const NOTEPAD_DELETE_REQUEST = "NOTEPAD_DELETE_REQUEST";
export const NOTEPAD_DELETE_SUCCESS = "NOTEPAD_DELETE_SUCCESS";
export const NOTEPAD_DELETE_FAIL = "NOTEPAD_DELETE_FAIL";
export const NOTEPAD_DELETE_ACKNOWLEDGE = "NOTEPAD_DELETE_ACKNOWLEDGE";