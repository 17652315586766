import React, {useState} from 'react';
import Layout from "../components/Layout/Layout";
import {useSelector} from "react-redux";
import swal from "sweetalert2";
import axios from "axios";

const Help = () => {
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [helpForm, setHelpForm] = useState({
        name: userInfo?.username || "",
        email: userInfo?.email || "",
        message: ""
    })

    async function sendHelp(e) {
        e.preventDefault();
        const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        // console.log(helpForm);
        try {
            if(!helpForm.name || !helpForm.email || !helpForm.message) {
                throw new Error("Please fill all the fields")
            }

            if(!emailPattern.test(helpForm.email)) {
                throw new Error("Email does not match pattern example@example.com");
            }

            if(helpForm.message.length <= 50) {
                throw new Error("Please try to explain your request/feedback in 50 characters or more")
            }

            const { data } = await axios.post('/api/email/request', { helpForm });

            if(!data) {
                throw new Error('Error sending email request');
            }

            if(!data.success) {
                throw new Error(data.message);
            }

            new swal({
                title: "Email Send",
                text: data.message,
                icon: "success",
                button: "OK",
            }).then((res) => resetForm())
        } catch (e) {
            new swal({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            })
        }
    }

    function resetForm() {
        setHelpForm({
            name: userInfo?.username || "",
            email: userInfo?.email || "",
            message: ""
        })
    }

    return (
        <Layout>
            <div className={'flex flex-col flex-grow mx-auto space-y-2'}>
                <h1 className={'text-center text-2xl'}>Help and Feedback</h1>
                <h4 className={'text-center text-md bg-orange-400 rounded-md p-2 mx-auto'}>Use this form if you need help with any of the sites features, or if you have suggestions and feedback! The goal of this app is to be a multipurpose tool that you can use anywhere, so if there is something you would like added feel free to send an email!</h4>
                <form onReset={resetForm} onSubmit={sendHelp} className={'flex flex-col space-y-2 w-full'}>
                    <div className={'flex flex-col text-start'}>
                        <label>Your Name</label>
                        <input className={'text-black p-1 rounded-md'} type={'text'} value={helpForm.name} onChange={(e) => setHelpForm({...helpForm, name: e.target.value})} />
                    </div>
                    <div className={'flex flex-col text-start'}>
                        <label>Your Email</label>
                        <input className={'text-black p-1 rounded-md'} type={'text'} value={helpForm.email} onChange={(e) => setHelpForm({...helpForm, email: e.target.value})} />
                    </div>
                    <div className={'flex flex-col text-start'}>
                        <label>Message</label>
                        <textarea rows={8} className={'text-black p-1 rounded-md resize-none'} value={helpForm.message} onChange={(e) => setHelpForm({...helpForm, message: e.target.value})} />
                    </div>
                    <div className={'flex flex-row content-center items-center justify-end space-x-2'}>
                        <button type={'reset'} className={'flex bg-red-500 hover:bg-red-700 rounded-md p-2 w-36'}>Reset</button>
                        <button type={'submit'} className={'flex bg-green-500 hover:bg-green-700 rounded-md p-2 w-36'}>Submit</button>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default Help;