/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    closestCenter,
    DndContext,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import GroceryItem from "./GroceryItem";
import {updateGroceryItem} from "../../redux-components/actions/groceryActions";
import GroceryHeader from "./GroceryHeader";
import {GROCERY_ITEM_CREATE_ACKNOWLEDGE} from "../../redux-components/constants/groceryConstants";

const GrocerySortable = () => {
    const dispatch = useDispatch();

    const groceryItemDelete = useSelector(state => state.groceryItemDelete);
    const { success: groceryItemDeleteSuccess, error: groceryItemDeleteError, id: groceryItemDeleteId } = groceryItemDelete;
    const groceryItemGet = useSelector(state => state.groceryItemGet);
    const { loading: groceryItemGetLoading, error: groceryItemGetError, groceryItems, length } = groceryItemGet;
    const groceryItemCreate = useSelector(state => state.groceryItemCreate);
    const { success: groceryItemCreateSuccess, error: groceryItemCreateError, groceryItem: newItem } = groceryItemCreate;

    const [items, setItems] = useState();
    const [activeId, setActiveId] = useState(null);

    useEffect(() => {
        setItems(groceryItems);
    }, [groceryItems])

    useEffect(() => {
        setItems(items?.filter((item) => item._id !== groceryItemDeleteId))
    }, [groceryItemDeleteSuccess, groceryItemDeleteId])

    useEffect(() => {
        if(newItem) {
            const newList = [newItem, ...items];
            setItems(newList);
            dispatch({type: GROCERY_ITEM_CREATE_ACKNOWLEDGE})
        }
    }, [dispatch, newItem, items, groceryItemCreateSuccess])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 5,
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 5,
            }
        }),
    );
    function handleDragStart(event) {
        setActiveId(event.active.id);
    }
    function handleDragEnd(event) {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex((it) => it._id === active?.id);
                const newIndex = items.findIndex((it) => it._id === over?.id);

                let newItems = arrayMove(items, oldIndex, newIndex);
                let index = 0;
                newItems.forEach(item => {
                    dispatch(updateGroceryItem(item._id, item.description, index));
                    index++;
                })

                return arrayMove(items, oldIndex, newIndex);
            });
        }
        setActiveId(null);
    }

    return (
        <>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={items?.map(item => item._id) || []}
                    strategy={verticalListSortingStrategy}
                >
                    {items?.map((da) =>
                        da.isHeader ? (<GroceryHeader {...da} key={da._id} />) : (<GroceryItem {...da} key={da._id} />)
                    )}
                </SortableContext>
            </DndContext>
        </>
    );
};

export default GrocerySortable;
