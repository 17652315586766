import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {customSwal} from "../../CustomSwal";
import {
    deleteChecklistCategory,
    updateChecklistCategory
} from "../../../redux-components/actions/checklistActions";

const ChecklistCategoryTitle = props => {
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const editMode = useSelector((state) => state.editMode);

    useEffect(() => {
        setEdit(editMode)
    }, [editMode]);

    async function editCategoryHandler() {
        let newTitle;
        let newColor;
        await customSwal.fire({
            title: `Editing Category: [${props.title}]`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Category Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' value="${props.title}" />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Category Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            showDenyButton: true,
            denyButtonText: 'Delete Category',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    newTitle = document.getElementById('newTitle').value,
                    newColor = document.getElementById('newColor').value
                ]
            }
        }).then((result) => {
            if(result.isConfirmed && result.value) {
                let formValues = result.value;
                dispatch(updateChecklistCategory(props._id, formValues[0], formValues[1]));
            }
            if(result.isDenied) {
                dispatch(deleteChecklistCategory(props._id));
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        edit
        ? (
            <div onClick={editCategoryHandler} className={`h-14 rounded-md cursor-pointer flex flex-row text-center p-4 bg-zinc-500 hover:bg-zinc-700 items-center justify-between content-center`}>
                <p className={'text-2xl'}>{props.title}</p>
                <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                    <FontAwesomeIcon icon={props.isOpen ? "arrow-alt-circle-up" : "arrow-alt-circle-down"} />
                </div>
            </div>
            )
        : (
            <div onClick={props.onClick} className={`h-14 rounded-md cursor-pointer flex flex-row text-center p-4 ${props.color} items-center justify-between content-center`}>
                <p className={'text-2xl'}>{props.title}</p>
                <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                    <FontAwesomeIcon icon={props.isOpen ? "arrow-alt-circle-up" : "arrow-alt-circle-down"} />
                </div>
            </div>
        )
    );
};

export default ChecklistCategoryTitle;