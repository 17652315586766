import axios from 'axios';
import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_FAIL,
    USER_REGISTER_SUCCESS,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, USER_UPDATE_USER_SUCCESS
} from '../constants/userConstants';

export const register = (username, email, password) => async(dispatch) => {
    try {
        dispatch({type: USER_REGISTER_REQUEST});

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const { data } = await axios.post('/api/users/register', {username, email, password}, config);

        dispatch({type: USER_REGISTER_SUCCESS, payload: data});

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const login = (username, password) => async(dispatch) => {
    try {
        dispatch({type: USER_LOGIN_REQUEST});

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const { data } = await axios.post('/api/users/login', {username, password}, config);

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data});

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
}

export const logout = () => async(dispatch) => {
    localStorage.removeItem('userInfo');
    dispatch({ type: USER_LOGOUT });
}

export const resetPassword = (newPassword, confirmNewPassword) => async(dispatch, getState) => {
    try {
        dispatch({type: USER_RESET_PASSWORD_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post('/api/users/resetpassword', {newPassword, confirmNewPassword}, config);

        dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data});
    } catch (error) {
        dispatch({
            type: USER_RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
}

export const resetPasswordWithToken = (email, token, newPassword, confirmNewPassword) => async(dispatch, getState) => {
    try {
        dispatch({type: USER_RESET_PASSWORD_REQUEST});

        const { data } = await axios.post('/api/users/forgot/resetpassword', {email, token, newPassword, confirmNewPassword});

        if(data.success) {
            dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data});
        } else {
            throw new Error(data.message);
        }
    } catch (error) {
        dispatch({
            type: USER_RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
}

export const deleteAcc = (_id) => async(dispatch, getState) => {
    try {
        dispatch({type: USER_DELETE_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(`/api/users/delete/${_id}`, config);

        dispatch({type: USER_DELETE_SUCCESS, payload: data});

        logout();
    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const postAddress = (streetAddress, cityAddress, stateAddress, zipAddress) => async(dispatch, getState) => {
    try {
        dispatch({type: USER_UPDATE_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`/api/users/update/address`, {streetAddress, cityAddress, stateAddress, zipAddress}, config);

        dispatch({ type: USER_UPDATE_USER_SUCCESS, payload: data});

        localStorage.setItem('userInfo', JSON.stringify(data));

        logout();
    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const deleteAddress = () => async(dispatch, getState) => {
    try {
        dispatch({type: USER_UPDATE_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(`/api/users/update/address`, config);

        dispatch({ type: USER_UPDATE_USER_SUCCESS, payload: data});

        localStorage.setItem('userInfo', JSON.stringify(data));

        logout();
    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}