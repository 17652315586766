import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import Footer from './components/Layout/Footer'
import Header from './components/Layout/Header'

import Login from './pages/User-Management/Login'
import Register from './pages/User-Management/Register'

import Dev from './pages/Admin/Dev'

import Admin from './pages/Admin/Admin'
import Dashboard from './pages/Dashboard'
import Notes from './pages/Widgets/Notes'
import Shorts from './pages/Widgets/Shorts'
import Reminders from './pages/Widgets/Reminders'
import Grocery from "./pages/Widgets/Grocery";
import Error from "./pages/Error/Error";
import ResetPassword from "./pages/User-Management/ResetPassword";
import ForgotPassword from "./pages/User-Management/ForgotPassword";
import Checklist from "./pages/Admin/Checklist";
import Updates from "./pages/Updates";
import Help from "./pages/Help";
import BackgroundImage from "./components/Layout/BackgroundImage";
import Settings from "./pages/User-Management/Settings";

library.add(fab, fas, far);

const App = () => {
  return (
    <BrowserRouter>
      <BackgroundImage>
        <Header/>
          <section className='flex flex-grow p-4'>
              <Routes>
                {/*<Route path={'/dev'} element={<Dev/>} exact />*/}

                <Route path={'/'} element={<Dashboard/>} exact />
                <Route path={'/updates'} element={<Updates/>} exact />
                <Route path={'/help'} element={<Help/>} exact />
                <Route path={'*'} element={<Error/>} />
                <Route path={'/notes'} element={<Notes/>} exact />
                <Route path={'/shortcuts'} element={<Shorts/>} exact />
                <Route path={'/reminders'} element={<Reminders/>} exact />

                <Route path={'/register'} element={<Register/>} exact />
                <Route path={'/login'} element={<Login/>} exact />
                <Route path={'/resetpassword'} element={<ResetPassword/>} exact />
                <Route path={'/forgotpassword'} element={<ForgotPassword/>} exact />

                <Route path={'/settings'} element={<Settings/>} exact />
                <Route path={'/dashboard'} element={<Dashboard/>} exact />
                <Route path={'/admin'} element={<Admin/>} exact />
                <Route path={'/grocery'} element={<Grocery/>} exact />
                <Route path={'/checklist'} element={<Checklist/>} exact />
              </Routes>
          </section>
        <Footer/>
      </BackgroundImage>
    </BrowserRouter>
  )
}

export default App
