/* eslint-disable no-unused-vars */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createExpandableShortcutAction, createKiwiShortcutAction, createShortcutAction, deleteExpandableShortcutAction, deleteShortcutAction, loadShortcuts, updateExpandableShortcutAction, updateShortcutAction } from '../../redux-components/actions/shortcutActions';
import {ClipLoader} from 'react-spinners'
import Swal from 'sweetalert2';
import { customSwal } from '../CustomSwal'
import { SHORTCUT_CREATE_ACKNOWLEDGE, SHORTCUT_DELETE_ACKNOWLEDGE, SHORTCUT_UPDATE_ACKNOWLEDGE } from '../../redux-components/constants/shortcutConstants';
import EditButton from '../Layout/EditButton';
import { adminImportReminder, loadCategories, loadReminders } from '../../redux-components/actions/reminderActions';

const Loader = () => {
    return (
        <div className='rounded-full cursor-pointer h-14 w-14 text-white bg-black p-2 bg-opacity-50'>
            <ClipLoader color={`#ffffff`} size={40} />
        </div>
    )
}
const Error = () => {
    return (
        <div title='Error Loading Shortcuts' className='rounded-full cursor-pointer h-14 w-14 text-white bg-red-500 hover:bg-red-300 p-2 bg-opacity-50'>
            <FontAwesomeIcon icon='fa-solid fa-warning' size='2x' className='pt-0.5' />
        </div>
    )
}

const Shortcuts = ({solo}) => {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const shortcutGet = useSelector(state => state.shortcutGet);
    const { loading, error, shortcuts, length } = shortcutGet;
    const shortcutCreate = useSelector(state => state.shortcutCreate);
    const { success: successCreate, error: errorCreate } = shortcutCreate;
    const shortcutUpdate = useSelector(state => state.shortcutUpdate);
    const { success: successUpdate, error: errorUpdate } = shortcutUpdate;
    const shortcutDelete = useSelector(state => state.shortcutDelete);
    const { success: successDelete, error: errorDelete } = shortcutDelete;
    const editMode = useSelector((state) => state.editMode);

    useEffect(() => {
        dispatch(loadShortcuts());
    }, [dispatch, userInfo, successCreate, successUpdate, successDelete])

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    const editHandler = async(da) => {
        customSwal.fire({
            title: `Editing '${da.title}'`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' value='${da.title}' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Link</label>
                        <input id='newLink' class='p-2 rounded-md bg-zinc-300' type='text' value='${da.link}' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Icon</label>
                        <input id='newIcon' class='p-2 rounded-md bg-zinc-300' type='text' value='${da.icon}' />
                    </div>
                    <div class='flex flex-row text-black space-x-4 content-center items-center py-2'>
                        <input id='newExpandable' class='rounded-md bg-zinc-300' type='checkbox' ${da.isExpandable ? "checked" : ""} />
                        <label for='newExpandable' class='text-start text-xl font-bold'>Expandable</label>
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            showDenyButton: true,
            denyButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newLink').value,
                    document.getElementById('newIcon').value,
                    document.getElementById('newColor').value,
                    document.getElementById('newExpandable').checked
                ]
            }
        }).then((result) => {
            if(result.isConfirmed && result.value) {
                let formValues = result.value;
                dispatch(updateShortcutAction(da._id, formValues[0], formValues[1], formValues[2], formValues[3], formValues[4]))
            }
            if(result.isDenied) {
                dispatch(deleteShortcutAction(da._id))
            }
        })
    }

    const editInnerHandler = async(da, expandIndex, editID) => {
        customSwal.fire({
            title: `Editing '${da.title}'`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' value='${da.title}' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Link</label>
                        <input id='newLink' class='p-2 rounded-md bg-zinc-300' type='text' value='${da.link}' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Icon</label>
                        <input id='newIcon' class='p-2 rounded-md bg-zinc-300' type='text' value='${da.icon}' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            showDenyButton: true,
            denyButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newLink').value,
                    document.getElementById('newIcon').value,
                    document.getElementById('newColor').value,
                ]
            }
        }).then((result) => {
            if(result.isConfirmed && result.value) {
                let formValues = result.value;
                dispatch(updateExpandableShortcutAction(editID, formValues[0], formValues[1], formValues[2], formValues[3], expandIndex))
            }
            if(result.isDenied) {
                dispatch(deleteExpandableShortcutAction(editID, expandIndex))
            }
        })
    }

    const addHandler = async() => {
        const { value: formValues } = await customSwal.fire({
            title: `Create Your Shortcut`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Link</label>
                        <input id='newLink' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Icon</label>
                        <input id='newIcon' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-row text-black space-x-4 content-center items-center py-2'>
                        <input id='newExpandable' class='rounded-md bg-zinc-300' type='checkbox' />
                        <label for='newExpandable' class='text-start text-xl font-bold'>Expandable</label>
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newLink').value,
                    document.getElementById('newIcon').value,
                    document.getElementById('newColor').value,
                    document.getElementById('newExpandable').checked,
                ]
            }
        })

        if(formValues) {
            dispatch(createShortcutAction(formValues[0], formValues[1], formValues[2], formValues[3], formValues[4]));
        }
    }

    const addInnerHandler = async(editID) => {
        const { value: formValues } = await customSwal.fire({
            title: `Create Your Shortcut`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Link</label>
                        <input id='newLink' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Icon</label>
                        <input id='newIcon' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newLink').value,
                    document.getElementById('newIcon').value,
                    document.getElementById('newColor').value,
                ]
            }
        })

        if(formValues) {
            dispatch(createExpandableShortcutAction(editID, formValues[0], formValues[1], formValues[2], formValues[3], formValues[4]));
        }
    }

    // eslint-disable-next-line
    const adminKiwiHandler = async() => {
        const { value: formValues } = await customSwal.fire({
            title: `New Semester Classes`,
            html:
                `<div class='flex flex-row space-y-4 overflow-x-hidden'>
                    <div class='flex flex-col'>
                        <div class='flex flex-col text-black'>
                            <label class='text-start text-xl font-bold'>Class Title</label>
                            <input id='classOneTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                        </div>
                        <div class='flex flex-col text-black'>
                            <label class='text-start text-xl font-bold'>Link ID</label>
                            <input id='classOneLink' class='p-2 rounded-md bg-zinc-300' type='text' />
                        </div>
                        <div class='flex flex-col text-black'>
                            <label class='text-start text-xl font-bold'>Syllabus</label>
                            <input id='classOneSyl' class='p-2 rounded-md bg-zinc-300' type='text' />
                        </div>
                    </div>
                    <div class='flex flex-col'>
                            <div class='flex flex-col text-black'>
                                <label class='text-start text-xl font-bold'>Class Title</label>
                                <input id='classTwoTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                            </div>
                            <div class='flex flex-col text-black'>
                                <label class='text-start text-xl font-bold'>Link ID</label>
                                <input id='classTwoLink' class='p-2 rounded-md bg-zinc-300' type='text' />
                            </div>
                            <div class='flex flex-col text-black'>
                                <label class='text-start text-xl font-bold'>Syllabus</label>
                                <input id='classTwoSyl' class='p-2 rounded-md bg-zinc-300' type='text' />
                            </div>
                        </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('classOneTitle').value,
                    document.getElementById('classOneLink').value,
                    document.getElementById('classOneSyl').value,
                    document.getElementById('classTwoTitle').value,
                    document.getElementById('classTwoLink').value,
                    document.getElementById('classTwoSyl').value,
                ]
            }
        })
        if(formValues) {
            dispatch(createKiwiShortcutAction(formValues[0], formValues[1], formValues[2], formValues[3], formValues[4], formValues[5]))
        }
    }

    // eslint-disable-next-line
    const adminImportHandler = async() => {
        const { value: formValues } = await customSwal.fire({
            title: `Reminder Import`,
            html:
                `<div class='flex flex-row space-y-4 overflow-x-hidden'>
                    <div class='flex flex-col'>
                        <div class='flex flex-col text-black'>
                            <label class='text-start text-xl font-bold'>Import JSON</label>
                            <input id='importedFile' class='p-2 rounded-md bg-zinc-300' type='file' />
                        </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('importedFile'),
                ]
            }
        })
        if(formValues) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                try {
                    dispatch(adminImportReminder(JSON.parse(fileReader.result)));
                    dispatch(loadCategories());
                    dispatch(loadReminders());
                } catch(e) {
                    new Swal({
                        title: "Error",
                        text: 'Could not Parse JSON ' + e,
                        icon: "error",
                        button: "OK",
                      })
                }
            }

            if(formValues[0].files[0] !== undefined) {
                fileReader.readAsText(formValues[0].files[0])
            }

        }
    }

    if(errorCreate) {
        Swal.fire({
            title: "Error",
            text: errorCreate,
            icon: "error",
            button: "OK",
          }).then(dispatch({type: SHORTCUT_CREATE_ACKNOWLEDGE}));
    }

    if(errorUpdate) {
        Swal.fire({
            title: "Error",
            text: errorUpdate,
            icon: "error",
            button: "OK",
          }).then(dispatch({type: SHORTCUT_UPDATE_ACKNOWLEDGE}));
    }

    if(errorDelete) {
        Swal.fire({
            title: "Error",
            text: errorDelete,
            icon: "error",
            button: "OK",
          }).then(dispatch({type: SHORTCUT_DELETE_ACKNOWLEDGE}));
    }

    const [expandIndex, setExpandIndex] = useState();
    const [editID, setEditID] = useState();

  return (
    <>
        {loading && <Loader />}
        {error && <Error />}
        {shortcuts?.map((da, index) =>
            edit
            ? (
                da.isExpandable ? (
                    <div key={'edit_expandable_icon_'+da._id+'_'+index} onMouseOver={() => {setExpandIndex(index); setEditID(da._id);}} onMouseLeave={() => setExpandIndex()} className='flex flex-row flex-wrap md:flex-nowrap lg:pr-2 lg:pb-0 pb-2'>
                        <div className={`bg-zinc-700 mt-16 lg:mt-0 lg:ml-16 z-50 absolute gap-2 flex flex-col lg:flex-row ${expandIndex === index ? "" : "hidden"}`}>
                            {da.expandedShortcuts?.map((inner, index) =>
                                <div onClick={() => {editInnerHandler(inner, index, da._id)}} key={'edit_expandable_item_'+da._id}>
                                    <div title={inner.title} className={`flex items-center justify-center bg-gray-500 ${inner.color.split(' ')[1]} cursor-pointer p-2 rounded-full h-14 w-14`}>
                                        <FontAwesomeIcon icon={inner.icon} size='2x' />
                                    </div>
                                </div>
                            )}
                                <div className={`${da.expandedShortcuts.length >= 5 ? "hidden" : ""}`}>
                                    <div onClick={() => addInnerHandler(editID)} title='Add Expandable Shortcut' className={`flex items-center justify-center bg-gray-500 hover:bg-zinc-900 cursor-pointer p-2 rounded-full h-14 w-14`}>
                                        <FontAwesomeIcon icon='plus' size='2x' />
                                    </div>
                                </div>
                        </div>
                        <div onClick={()=>editHandler(da)}>
                            <div target='_blank' title={da.title} className={`flex items-center justify-center bg-gray-500 ${da.color.split(' ')[1]} cursor-pointer p-2 rounded-full h-14 w-14`}>
                                <FontAwesomeIcon icon={da.icon} size='2x' />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div onClick={()=>editHandler(da)}>
                        <div key={'edit_nonexpandable_main_icon_'+da._id} target='_blank' title={da.title} className={`flex items-center justify-center bg-gray-500 ${da.color.split(' ')[1]} cursor-pointer p-2 rounded-full h-14 w-14`}>
                            <FontAwesomeIcon icon={da.icon} size='2x' />
                        </div>
                    </div>
                )
            )
            : (
                da.isExpandable ? (
                    <div onMouseOver={() => setExpandIndex(index)} onMouseLeave={() => setExpandIndex()} onClick={() => setExpandIndex()} className='flex flex-row lg:pr-2 pb-2 lg:pb-0'>
                        <div className={`mt-16 ${solo ? "" : "lg:mt-0 lg:ml-16"} z-50 absolute gap-2 flex flex-col lg:flex-row ${expandIndex === index ? "" : "hidden"}`}>
                            {da.expandedShortcuts?.map((inner, innerIndex) =>
                                <a key={'nonedit_expandable_icon_'+da._id+'_'+innerIndex} href={inner.link} target='_blank' rel='noreferrer'>
                                    <div title={inner.title} className={`flex items-center justify-center ${inner.color} cursor-pointer p-2 rounded-full h-14 w-14`}>
                                        <FontAwesomeIcon icon={inner.icon} size='2x' />
                                    </div>
                                </a>
                            )}
                        </div>
                        <a href={da.link} target='_blank' rel='noreferrer'>
                            <div key={'nonedit_expandable_main_icon_'+da._id+'_'+index} target='_blank' title={da.title} className={`flex items-center justify-center ${da.color} p-2 rounded-full h-14 w-14`}>
                                <FontAwesomeIcon icon={da.icon} size='2x' />
                            </div>
                        </a>
                    </div>
                ) : (
                    <a href={da.link} target='_blank' rel='noreferrer'>
                        <div key={'nonedit_nonexpandable_main_icon_'+da._id+'_'+index} target='_blank' title={da.title} className={`flex items-center justify-center ${da.color} p-2 rounded-full h-14 w-14`}>
                            <FontAwesomeIcon icon={da.icon} size='2x' />
                        </div>
                    </a>
                )
            )
        )}
        {(length <= 0 || length <= 7) ? (
            <div className={`${(length >= 1 ? edit ? "" : "hidden" : "")}`}>
                <div onClick={addHandler} title={'Add a Shortcut'} className='flex items-center justify-center bg-gray-500 hover:bg-zinc-900 cursor-pointer p-2 rounded-full h-14 w-14'>
                    <FontAwesomeIcon icon={'fa-solid fa-plus'} size='2x' />
                </div>
            </div>
        ) : ""}
        <div className='lg:flex lg:flex-grow hidden'></div>
        {userInfo
            ? userInfo.isAdmin ? (
                <>
                    {/*
                    <div title='Import Reminders' onClick={adminImportHandler} target='_blank' className={` ${edit ? "" : "hidden"} flex items-center justify-center bg-gray-500 hover:bg-zinc-900 cursor-pointer p-2 rounded-full h-14 w-14`}>
                        <FontAwesomeIcon icon='fas fa-file-import' size='2x' />
                    </div>


                    <div title='Create Semester Shortcuts' onClick={adminKiwiHandler} target='_blank' className={` ${edit ? "" : "hidden"} flex items-center justify-center bg-gray-500 hover:bg-zinc-900 cursor-pointer p-2 rounded-full h-14 w-14`}>
                        <FontAwesomeIcon icon='fas fa-kiwi-bird' size='2x' />
                    </div>

                    discontinued for now
                    */}
                </>
            ) : ""
            : ""
        }
    </>
  )
}

export default Shortcuts