import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove} from "@dnd-kit/sortable";
import {
    updateChecklistItem
} from "../../../redux-components/actions/checklistActions";
import ChecklistCategory from "./ChecklistCategory";

const ChecklistSortable = props => {
    const dispatch = useDispatch();

    const checklistCategoryGet = useSelector(state => state.checklistCategoryGet);
    // eslint-disable-next-line
    const { loading: checklistCategoryGetLoading, error: checklistCategoryGetError, checklistCategorys } = checklistCategoryGet;
    const [items, setItems] = useState();
    // eslint-disable-next-line
    const [activeId, setActiveId] = useState(null);


    useEffect(() => {
        setItems(props.items);
    }, [])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 5,
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 5,
            }
        }),
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
        // console.log(`drag start on ${event.active.id}`)
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        // console.log(`drag end on ${activeId}`)

        if (active && over && active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex((it) => it._id === active?.id);
                const newIndex = items.findIndex((it) => it._id === over?.id);

                let newItems = arrayMove(items, oldIndex, newIndex);
                let index = 0;
                newItems.forEach(item => {
                    //dispatch(update(item._id, item.description, index));
                    // console.log(`dispatch(updateChecklist(${item._id}, ${item.description}, ${index}))`)
                    dispatch(updateChecklistItem(item._id, item.description, item.checked, index))
                    index++;
                })

                // console.log(arrayMove(items, oldIndex, newIndex))
                return arrayMove(items, oldIndex, newIndex);
            });
        }
        setActiveId(null);
    }

    return (
        <>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <div className={'flex flex-col space-y-2'}>
                    <ChecklistCategory {...props} items={items} />
                </div>
            </DndContext>
        </>
    );
};

export default ChecklistSortable;