import axios from "axios";
import {
    USER_UPDATE_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS
} from "../constants/userConstants";

export const updateBgQuery = (query) => async(dispatch, getState) => {
    try {
        dispatch({type: USER_UPDATE_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put('/api/users/settings/update/bgquery', {query}, config);

        let newUserInfo = JSON.parse(localStorage.getItem('userInfo'));
        newUserInfo.settings = data;
        localStorage.setItem('userInfo', JSON.stringify(newUserInfo));

        dispatch({ type: USER_UPDATE_SUCCESS, payload: data});
    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
}