import axios from "axios";
import {
    GROCERY_FAVORITE_CREATE_FAIL,
    GROCERY_FAVORITE_CREATE_REQUEST,
    GROCERY_FAVORITE_CREATE_SUCCESS,
    GROCERY_FAVORITE_DELETE_ALL_FAIL,
    GROCERY_FAVORITE_DELETE_ALL_REQUEST,
    GROCERY_FAVORITE_DELETE_ALL_SUCCESS,
    GROCERY_FAVORITE_DELETE_FAIL,
    GROCERY_FAVORITE_DELETE_REQUEST,
    GROCERY_FAVORITE_DELETE_SUCCESS,
    GROCERY_FAVORITE_GET_FAIL,
    GROCERY_FAVORITE_GET_REQUEST,
    GROCERY_FAVORITE_GET_SUCCESS,
    GROCERY_FAVORITE_UPDATE_FAIL,
    GROCERY_FAVORITE_UPDATE_REQUEST,
    GROCERY_FAVORITE_UPDATE_SUCCESS,
    GROCERY_ITEM_CREATE_FAIL,
    GROCERY_ITEM_CREATE_REQUEST,
    GROCERY_ITEM_CREATE_SUCCESS,
    GROCERY_ITEM_DELETE_ALL_FAIL,
    GROCERY_ITEM_DELETE_ALL_REQUEST,
    GROCERY_ITEM_DELETE_ALL_SUCCESS,
    GROCERY_ITEM_DELETE_FAIL,
    GROCERY_ITEM_DELETE_REQUEST,
    GROCERY_ITEM_DELETE_SUCCESS,
    GROCERY_ITEM_GET_FAIL,
    GROCERY_ITEM_GET_REQUEST,
    GROCERY_ITEM_GET_SUCCESS,
    GROCERY_ITEM_UPDATE_FAIL,
    GROCERY_ITEM_UPDATE_REQUEST,
    GROCERY_ITEM_UPDATE_SUCCESS,
    GROCERY_UNDO_LIST_FAIL,
    GROCERY_UNDO_LIST_SUCCESS
} from "../constants/groceryConstants";

export const loadGroceryItems = () => async(dispatch, getState) => {
    try {
        dispatch({type: GROCERY_ITEM_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/grocery/`, config);

        dispatch({type: GROCERY_ITEM_GET_SUCCESS, payload: data});

    } catch (error) {
        dispatch({
            type: GROCERY_ITEM_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const loadGroceryFavorites = () => async(dispatch, getState) => {
    try {
        dispatch({type: GROCERY_FAVORITE_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/grocery/favorites`, config);

        dispatch({type: GROCERY_FAVORITE_GET_SUCCESS, payload: data});

    } catch (error) {
        dispatch({
            type: GROCERY_FAVORITE_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const createGroceryItem = (description) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_ITEM_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/grocery/create`, { description }, config);

        dispatch({
            type: GROCERY_ITEM_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_ITEM_CREATE_FAIL,
            payload: message,
        });
    }
}

export const createGroceryHeader = (description) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_ITEM_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/grocery/create/header`, { description }, config);

        dispatch({
            type: GROCERY_ITEM_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_ITEM_CREATE_FAIL,
            payload: message,
        });
    }
}

export const createGroceryFavorite = (description) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_FAVORITE_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/grocery/favorites/add`, { description }, config);

        dispatch({
            type: GROCERY_FAVORITE_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_FAVORITE_CREATE_FAIL,
            payload: message,
        });
    }
}

export const updateGroceryItem = (id, description, position) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_ITEM_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/grocery/update/${id}`, { description, position }, config);

        dispatch({
            type: GROCERY_ITEM_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_ITEM_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const updateGroceryFavorites= (id, description, position) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_FAVORITE_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/grocery/update/${id}`, { description, position }, config);

        dispatch({
            type: GROCERY_FAVORITE_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_FAVORITE_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const deleteGroceryItem = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_ITEM_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/grocery/delete/${id}`, config);

        dispatch({
            type: GROCERY_ITEM_DELETE_SUCCESS,
            payload: id,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_ITEM_DELETE_FAIL,
            payload: message,
        });
    }
}

export const deleteGroceryFavorite = (description) => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_FAVORITE_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/grocery/favorites/delete/single`, {description},  config);

        dispatch({
            type: GROCERY_FAVORITE_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_FAVORITE_DELETE_FAIL,
            payload: message,
        });
    }
}

export const deleteAllGroceryItem = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_ITEM_DELETE_ALL_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/grocery/delete`, config);

        dispatch({
            type: GROCERY_ITEM_DELETE_ALL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_ITEM_DELETE_ALL_FAIL
            ,
            payload: message,
        });
    }
}

export const deleteAllGroceryFavorites = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: GROCERY_FAVORITE_DELETE_ALL_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/grocery/favorites/delete`, {}, config);

        dispatch({
            type: GROCERY_FAVORITE_DELETE_ALL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_FAVORITE_DELETE_ALL_FAIL
            ,
            payload: message,
        });
    }
}

export const updateGroceryUndoList = (item) => async(dispatch, getState) => {
    try {
        const { groceryUndoList: { undoList = [] } = {} } = getState();

        dispatch({
            type: GROCERY_UNDO_LIST_SUCCESS,
            payload: undoList.concat(item),
        });

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_UNDO_LIST_FAIL
            ,
            payload: message,
        });
    }
}

export const removeGroceryUndoList = () => async(dispatch, getState) => {
    try {
        const { groceryUndoList: { undoList = [] } = {} } = getState();
        const newUndoList = [...undoList];
        newUndoList.pop();

        dispatch({
            type: GROCERY_UNDO_LIST_SUCCESS,
            payload: newUndoList,
        });

    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: GROCERY_UNDO_LIST_FAIL
            ,
            payload: message,
        });
    }
}