/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReminderItem from './ReminderItem';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, createReminders, deleteCategory, deleteCategoryAll, loadCategories, loadReminders, updateCategory } from '../../redux-components/actions/reminderActions';
import Loading from '../Layout/Loading';
import Swal from 'sweetalert2';
import moment from "moment";
import { REMINDER_CATEGORY_CREATE_ACKNOWLEDGE, REMINDER_CREATE_ACKNOWLEDGE } from '../../redux-components/constants/reminderConstants';
import {escapeHtml} from "../Helpers/escapeHtml";
import EmptyReminderWidget from "./EmptyReminderWidget";

const ReminderWidget = () => {
    const dispatch = useDispatch();

    const customSwal = Swal.mixin({
        customClass: {
            confirmButton: 'bg-green-500 hover:bg-green-300 px-4 py-2 text-lg text-white rounded-md ml-2',
            cancelButton: 'bg-blue-500 hover:bg-blue-300 px-4 py-2 text-lg text-white rounded-md',
            denyButton: 'bg-red-500 hover:bg-red-300 px-4 py-2 text-lg text-white rounded-md ml-2',
        },
        buttonsStyling: false
    })
    const customDelSwal = Swal.mixin({
        customClass: {
            confirmButton: 'bg-red-500 hover:bg-red-300 px-4 py-2 text-lg text-white rounded-md ml-2',
            cancelButton: 'bg-blue-500 hover:bg-blue-300 px-4 py-2 text-lg text-white rounded-md',
            denyButton: 'bg-red-500 hover:bg-red-300 px-4 py-2 text-lg text-white rounded-md ml-2',
        },
        buttonsStyling: false
    })

    const editMode = useSelector((state) => state.editMode);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const categoryGet = useSelector(state => state.categoryGet);
    const { loading: categoryGetLoading, error: categoryGetError, categories, length, first } = categoryGet;
    const reminderGet = useSelector(state => state.reminderGet);
    const { loading: reminderGetLoading, error: reminderGetError, reminders } = reminderGet;
    const reminderCreate = useSelector(state => state.reminderCreate);
    const { success: reminderCreateSuccess, error: reminderCreateError } = reminderCreate;
    const categoryCreate = useSelector(state => state.categoryCreate);
    const { success: categoryCreateSuccess, error: categoryCreateError } = categoryCreate;
    const categoryUpdate = useSelector(state => state.categoryUpdate);
    const { success: categoryUpdateSuccess, error: categoryUpdateError } = categoryUpdate;
    const reminderUpdate = useSelector(state => state.reminderUpdate);
    const { success: reminderUpdateSuccess, error: reminderUpdateError } = reminderUpdate;
    const reminderDelete = useSelector(state => state.reminderDelete);
    const { success: reminderDeleteSuccess, error: reminderDeleteError } = reminderDelete;
    const categoryDelete = useSelector(state => state.categoryDelete);
    const { success: categoryDeleteSuccess, error: categoryDeleteError } = categoryDelete;
    const categoryDeleteAll = useSelector(state => state.categoryDeleteAll);
    const { success: categoryDeleteAllSuccess, error: categoryDeleteAllError } = categoryDeleteAll;
    const adminImportReminder = useSelector(state => state.adminImportReminder);
    const { success: adminImportReminderSuccess, error: adminImportReminderError } = adminImportReminder;

    const [edit, setEdit] = useState(false);
    const [open, setOpen] = useState("");
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        async function categoryLoad() {
            await dispatch(loadCategories());
        }
        categoryLoad();
    }, [dispatch, userInfo, categoryCreateSuccess, categoryUpdateSuccess, categoryDeleteSuccess, categoryDeleteAllSuccess, adminImportReminderSuccess])

    useEffect(() => {
        const fetchReminders = async() => {
            await dispatch(loadReminders());
        }

        if(!initial) {
            fetchReminders()
        } else {
            fetchReminders().then(() => {
                setOpen(first)
                setInitial(false)
            })
        }
    }, [dispatch, userInfo, first, initial, reminderCreateSuccess, reminderUpdateSuccess, reminderDeleteSuccess])

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    const createReminderHandler = async() => {
        let today = new Date().toISOString().split('T')[0];
        let todayTime = new Date();
        let h = todayTime.getHours();
        let m = todayTime.getMinutes();
        if(h < 10) h = '0' + h;
        if(m < 10) m = '0' + m;
        const { value: formValues } = await customSwal.fire({
            title: `Create Reminder`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Due Date</label>
                        <div class='flex flex-row space-x-2 text-black'>
                            <input id='newDate' class='p-2 rounded-md bg-zinc-300 flex flex-grow' value="${today}" type='date' />
                            <input id='newTime' class='p-2 rounded-md bg-zinc-300 flex flex-grow' value="${h + ':' + m}" type='time' />
                        </div>
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Recurring Type</label>
                        <select id='recurringType' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-zinc-300' value='none'>None</option>
                            <option class='bg-zinc-300' value='hourly'>Hourly</option>
                            <option class='bg-zinc-300' value='daily'>Daily</option>
                            <option class='bg-zinc-300' value='weekly'>Weekly</option>
                            <option class='bg-zinc-300' value='monthly'>Monthly</option>
                            <option class='bg-zinc-300' value='yearly'>Yearly</option>
                        </select>
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Description</label>
                        <textarea id='newDescription' rows="5" class='p-2 rounded-md bg-zinc-300'>${userInfo ? userInfo.isAdmin ? "^w^" : "reminder text" :  ""}</textarea>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                let formDate = document.getElementById('newDate').value
                let formTime = document.getElementById('newTime').value
                let newDate;
                if(formDate !== "" && formTime !== "") {
                    newDate = new Date(formDate + " " + formTime).toISOString();
                } else if(formDate !== "" && formTime === "") {
                    newDate = new Date(formDate).toISOString();
                } else {
                    newDate = undefined;
                }

                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newDescription').value,
                    newDate,
                    document.getElementById('recurringType').value
                ]
            }
        })

        if(formValues) {
            //console.log(formValues);
            const escapedTitle = escapeHtml(formValues[0]);
            const escapedContent = escapeHtml(formValues[1]);
            dispatch(createReminders(open, escapedTitle, escapedContent, formValues[2], formValues[3]));
        }
    }

    const createCategoryHandler = async() => {
        const { value: formValues } = await customSwal.fire({
            title: `Create a Category`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Category Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Category Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newColor').value,
                ]
            }
        })

        if(formValues) {
            dispatch(createCategory(formValues[0], formValues[1]));
        }
    }

    const editCategoryHandler = async(oldTitle) => {
        const { value: formValues } = await customSwal.fire({
            title: `Editing "${oldTitle}"`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>New Category Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                ]
            }
        })

        if(formValues) {
            dispatch(updateCategory(open, formValues[0]));
        }
    }

    const deleteHandler = async() => {
        customDelSwal.fire({
            title: `What would you like to do?`,
            showCancelButton: true,
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: 'Delete Category',
            denyButtonText: 'Delete all Reminders',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if(result.isDenied) {
                //delete all reminders
                dispatch(deleteCategoryAll(open));
            } else if(result.isConfirmed) {
                //delete category
                dispatch(deleteCategory(open));
            }
        })
    }

    if(reminderCreateError) {
        Swal.fire({
            title: "Error",
            text: reminderCreateError,
            icon: "error",
            button: "OK",
          }).then(dispatch({type: REMINDER_CREATE_ACKNOWLEDGE}));
    }

    if(categoryCreateError) {
        Swal.fire({
            title: "Error",
            text: categoryCreateError,
            icon: "error",
            button: "OK",
          }).then(dispatch({type: REMINDER_CATEGORY_CREATE_ACKNOWLEDGE}));
    }

  return (
    <div className='flex flex-col space-y-2'>
    {(length < 1 || categoryGetError) && <EmptyReminderWidget />}
    {categoryGetLoading && <Loading size={25} padding={"p-8"} rounded={"rounded-lg"} opacity={"bg-opacity-50"} />}
        {categories?.map((da, index) =>
            <>
                <div key={'reminderCategory_'+da._id+'_'+index} className={`${edit ? "bg-zinc-500 hover:bg-zinc-700" : da.color} h-14 rounded-md flex text-start p-2 cursor-pointer`} onClick={()=>{ open === da._id ? setOpen("") : setOpen(da._id)}}>
                    <div className='flex items-center p-2 flex-auto'>
                        <div>
                            <h1 className='text-2xl'>{da.title}</h1>
                        </div>
                        <div className='flex flex-grow'></div>
                        <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                            <FontAwesomeIcon icon={open === da._id ? "arrow-alt-circle-up" : "arrow-alt-circle-down"} />
                        </div>
                    </div>
                </div>
                <div className={`${open === da._id ? "space-y-2 flex flex-col" : "hidden"} overflow-auto empty-scroll`} style={{maxHeight: "550px"}}>
                    {
                        edit
                        ? ""
                        : (
                            <div onClick={createReminderHandler} className={`sticky top-0 bg-green-700 hover:bg-green-600 h-8 rounded-md flex text-start p-2 cursor-pointer`}>
                                <div className='flex items-center p-2 flex-auto'>
                                    <div>
                                        <h1 className='text-lg'>Create Reminder</h1>
                                    </div>
                                    <div className='flex flex-grow'></div>
                                    <div className={`flex items-center justify-center hover:bg-zinc-700 cursor-pointer p-2 rounded-full h-7 w-7`}>
                                        <FontAwesomeIcon icon="fas fa-plus" />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {reminderGetLoading && <Loading size={25} padding={"p-6"} rounded={"rounded-lg"} opacity={"bg-opacity-50"} />}
                    {
                        edit
                        ?   (
                            <div className='sticky top-0 space-x-4 cursor-pointer flex flex-row text-start rounded-lg'>
                                <div onClick={createReminderHandler} className='flex items-center justify-between p-2 flex-auto gap-2 bg-green-400 hover:bg-green-700 rounded-md'>
                                    <div className='flex flex-row flex-grow font-bold'>
                                        <div>
                                            <p>Create</p>
                                        </div>
                                        <div className='flex flex-grow'></div>
                                        <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                                            <FontAwesomeIcon icon="fas fa-pencil" />
                                        </div>
                                    </div>
                                </div>
                                <div onClick={()=>{editCategoryHandler(da.title)}} className='flex items-center justify-between p-2 flex-auto bg-blue-400 hover:bg-blue-700 rounded-md'>
                                    <div className='flex flex-row flex-grow font-bold'>
                                        <div>
                                            <p>Edit</p>
                                        </div>
                                        <div className='flex flex-grow'></div>
                                        <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                                            <FontAwesomeIcon icon="fas fa-pencil" />
                                        </div>
                                    </div>
                                </div>
                                <div onClick={deleteHandler} className='flex items-center justify-between p-2 flex-auto gap-2 bg-red-400 hover:bg-red-700 rounded-md'>
                                    <div className='flex flex-row flex-grow font-bold'>
                                        <div>
                                            <p>Delete</p>
                                        </div>
                                        <div className='flex flex-grow'></div>
                                        <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                                            <FontAwesomeIcon icon="fas fa-trash" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :   ""
                    }

                    {reminders?.map((mind, mindex) =>
                        mind.reminderCategory === da._id
                        ?   (
                                <ReminderItem key={mind._id+'_'+mindex} userAdmin={userInfo.isAdmin} title={mind.title} due={mind.reminderDate} content={mind.description} reminderID={mind._id} messageSent={mind.messageSent} recurringType={mind.recurringType} />
                            )
                        : ""
                    )}

                </div>
            </>
        )}
        {
            edit
            ?   (
                <div onClick={createCategoryHandler} className={`bg-zinc-500 hover:bg-zinc-700 h-14 rounded-md flex text-start p-2 cursor-pointer`}>
                    <div className='flex items-center p-2 flex-auto'>
                        <div>
                            <h1 className='text-2xl'>Create Category</h1>
                        </div>
                        <div className='flex flex-grow'></div>
                        <div className={`flex items-center justify-center hover:bg-zinc-700 cursor-pointer p-2 rounded-full h-7 w-7`}>
                            <FontAwesomeIcon icon="fas fa-plus" />
                        </div>
                    </div>
                </div>
            )
            : ""
        }
    </div>
  )
}

export default ReminderWidget