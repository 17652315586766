import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Layout/Loading';
import swal from 'sweetalert2';
import { register } from '../../redux-components/actions/userActions';
import { USER_REGISTER_FAIL_ACKNOWLEDGE } from '../../redux-components/constants/userConstants';
import parse from "url-parse";

const Register = () => {

    const location = useLocation();
    const parsed = parse(location.search, true);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const history = useNavigate();
    const dispatch = useDispatch();

    const userRegister = useSelector((state) => state.userRegister);
    const { loading, error, accountCreated } = userRegister;

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo: userLoggedInfo } = userLogin;

    useEffect(() => {
        if(userLoggedInfo || accountCreated) {
            new swal({
                title: "Success",
                text: "Account was created Successfully",
                icon: "success",
                button: "Continue to Login",
            }).then(() => {
                history(`/login?next=${parsed.query.next}`);
            })
        }
    }, [userLoggedInfo, accountCreated, history]);

    if(error) {
        new swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "OK",
        }).then(() => {
            dispatch({type: USER_REGISTER_FAIL_ACKNOWLEDGE});
        })
    }

    const submitHandler = async(e) => {
        e.preventDefault();
        const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#:.;?!@$ %^&*-]).{8,}$/;
        //console.log(username, email, password);

        try {
            if(!username || !email || !password || !confirmPassword) {
                throw new Error("Please fill all the fields");
            }

            if(!emailPattern.test(email)) {
                throw new Error("Email does not match pattern example@example.com");
            }

            if(password !== confirmPassword) {
                throw new Error("Passwords do not match");
            }

            if(password.length < 8) {
                throw new Error("Password must be 8 characters long");
            }

            if(!passwordPattern.test(password)) {
                throw new Error("Password must be atleast 8 characters long with 1 capital, 1 number, and 1 symbol");
            }

            dispatch(register(username, email, password));
        } catch (e) {
            new swal({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            })
        }
    }

    const resetHandler = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
    }
    //TODO: make the useState an object: registerInfo = useState({username: null, etc...})

    return (
        <div className='items-center justify-center flex w-full md:w-3/6 text-center mx-auto'>
            <div className='flex flex-col backdrop-filter backdrop-blur-md bg-black rounded-lg bg-opacity-40 w-full'>
                {loading && <Loading absolute/>}
                <div className='p-4 rounded-lg'>
                    <h1 className='text-white p-2 mb-4 text-3xl'>Register</h1>
                    <form className='flex flex-col gap-4'>
                        <div className='flex flex-col gap-2'>
                            <label className='text-white text-start'>Username</label>
                            <input autoFocus value={username} onChange={(e) => setUsername(e.target.value)} type='text' className='rounded-md p-1.5'></input>
                        </div>
                        <div className='flex flex-col gap-2 text-start'>
                            <label className='text-white'>E-mail</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' className='rounded-md p-1.5'></input>
                        </div>
                        <div className='flex flex-col gap-2 text-start'>
                            <label className='text-white'>Password</label>
                            <input onChange={(e) => setPassword(e.target.value)} type='password' className='rounded-md p-1.5'></input>
                        </div>
                        <div className='flex flex-col gap-2 text-start'>
                            <label className='text-white'>Confirm Password</label>
                            <input onChange={(e) => setConfirmPassword(e.target.value)} type='password' className='rounded-md p-1.5'></input>
                        </div>
                        <div className='flex flex-row gap-2 justify-between'>
                            <button type='button' onClick={resetHandler} className='bg-red-500 rounded-md p-2 w-full hover:bg-red-400 text-white hover:text-black'>Reset</button>
                            <button type='submit' onClick={submitHandler} className='bg-green-500 rounded-md p-2 w-full hover:bg-green-400 text-white hover:text-black'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register