import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordWithToken} from "../../redux-components/actions/userActions";
import swal from "sweetalert2";
import MicroLayout from "../../components/Layout/MicroLayout";
import parse from "url-parse";
import axios from "axios";
import Swal from "sweetalert2";
import {USER_RESET_PASSWORD_FAIL_ACKNOWLEDGE} from "../../redux-components/constants/userConstants";

const ForgotPassword = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const parsed = parse(location.search, true);
    const [submitted, setSubmitted] = useState(false)

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const userReset = useSelector((state) => state.userReset);
    const { error: resetError  } = userReset;

    useEffect(() => {
        if(userInfo) {
            history('/login?next=/dashboard');
        }
    }, [userInfo, history])

    useEffect(() => {
        if(submitted) {
            if(!resetError) {
                Swal.fire({
                    title: "Success",
                    text: "Password updated successfully. Please login",
                    icon: "success",
                    button: "OK",
                }).then(() => {
                    setSubmitted(false);
                    history('/login');
                })
            } else {
                Swal.fire({
                    title: "Error",
                    text: `Password could not be updated: ${resetError}`,
                    icon: "error",
                    button: "OK",
                }).then(() => {
                    dispatch({type: USER_RESET_PASSWORD_FAIL_ACKNOWLEDGE})
                    setSubmitted(false);
                })
            }
        }
    }, [resetError, submitted]);

    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        if(!parsed.query.token || !parsed.query.email) {
            setToken("");
            setEmail("");
        } else {
            setToken(parsed.query.token)
            setEmail(parsed.query.email)
        }
    }, [history, parsed.query.token, parsed.query.email])

    const tokenHandler = async(e) => {
        e.preventDefault();
        const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        try {
            if(!email) {
                throw new Error('Please fill all the fields!')
            }

            if(!emailPattern.test(email)) {
                throw new Error("Email does not match pattern example@example.com");
            }

            const { data } = await axios.post('/api/users/forgot/sendtoken', { email });

            if(!data) {
                throw new Error('Error sending reset token');
            }

            if(!data.success) {
                throw new Error(data.message);
            }

            new swal({
                title: "Email Send",
                text: data.message,
                icon: "success",
                button: "OK",
            })
        } catch (e) {
            new swal({
                title: "Error",
                text: e,
                icon: "error",
                button: "OK",
            })
        }
    }

    const newPasswordHandler = async(e) => {
        e.preventDefault();
        const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#:.;?!@$ %^&*-]).{8,}$/;

        try {
            if(!password || !confirmPassword) {
                throw new Error('Please fill all the fields');
            }

            if(password !== confirmPassword) {
                throw new Error('Passwords do not match');
            }

            if(password.length < 8) {
                throw new Error('Password must be 8 characters long');
            }

            if(!passwordPattern.test(password)) {
                throw new Error("Password must be atleast 8 characters long with 1 capital, 1 number, and 1 symbol");
            }

            if(!token || token === "") {
                throw new Error('Token was invalid or empty. Please check your email and click the link')
            }

            //dispatch password update
            await dispatch(resetPasswordWithToken(email, token, password, confirmPassword));
            setSubmitted(true);
        } catch (e) {
            new swal({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            })
        }
    }

    return (
        <MicroLayout title={'Password Reset'} headTitle={token !== "" ? 'Verify Email' : 'Password Reset'}>
            <div className={'flex flex-col content-center items-center justify-center text-white text-start p-4'}>
                {
                    (token !== "") ?
                        (
                            <form className={'flex flex-col space-y-4 w-full'}>
                                <h1>{email}</h1>
                                <h1>{token}</h1>
                                <div className={'flex flex-col space-y-2'}>
                                    <label className='text-white'>New Password</label>
                                    <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' className='rounded-md p-1.5 text-black'></input>
                                </div>
                                <div className={'flex flex-col space-y-2'}>
                                    <label className='text-white'>Confirm New Password</label>
                                    <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' className='rounded-md p-1.5 text-black'></input>
                                </div>
                                <div className='flex flex-row gap-2 justify-between'>
                                    <button type='submit' onClick={newPasswordHandler} className='bg-green-500 rounded-md p-2 w-full hover:bg-green-400 text-white hover:text-black'>Reset Password</button>
                                </div>
                            </form>
                        )
                        : (
                            <form className={'flex flex-col space-y-4 w-full'}>
                            <div className={'flex flex-col space-y-2'}>
                                <label className='text-white'>Email</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' className='rounded-md p-1.5 text-black'></input>
                            </div>
                            <div className='flex flex-row gap-2 justify-between'>
                                <button type='submit' onClick={tokenHandler} className='bg-green-500 rounded-md p-2 w-full hover:bg-green-400 text-white hover:text-black'>Send Reset Email</button>
                            </div>
                        </form>
                        )
                }
            </div>
        </MicroLayout>
    );
};

export default ForgotPassword;
