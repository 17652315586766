import { USER_LOGOUT } from "../constants/userConstants";
import {
    GROCERY_FAVORITE_GET_FAIL,
    GROCERY_FAVORITE_GET_REQUEST,
    GROCERY_FAVORITE_GET_SUCCESS,
    GROCERY_FAVORITE_CREATE_ACKNOWLEDGE,
    GROCERY_FAVORITE_CREATE_FAIL,
    GROCERY_FAVORITE_CREATE_REQUEST,
    GROCERY_FAVORITE_CREATE_SUCCESS,
    GROCERY_FAVORITE_UPDATE_REQUEST,
    GROCERY_FAVORITE_UPDATE_SUCCESS,
    GROCERY_FAVORITE_UPDATE_FAIL,
    GROCERY_FAVORITE_UPDATE_ACKNOWLEDGE,
    GROCERY_FAVORITE_DELETE_REQUEST,
    GROCERY_FAVORITE_DELETE_SUCCESS,
    GROCERY_FAVORITE_DELETE_FAIL,
    GROCERY_FAVORITE_DELETE_ACKNOWLEDGE,
    GROCERY_FAVORITE_DELETE_ALL_FAIL,
    GROCERY_FAVORITE_DELETE_ALL_REQUEST,
    GROCERY_FAVORITE_DELETE_ALL_SUCCESS,
    GROCERY_FAVORITE_DELETE_ALL_ACKNOWLEDGE,
    GROCERY_ITEM_GET_FAIL,
    GROCERY_ITEM_GET_REQUEST,
    GROCERY_ITEM_GET_SUCCESS,
    GROCERY_ITEM_CREATE_ACKNOWLEDGE,
    GROCERY_ITEM_CREATE_FAIL,
    GROCERY_ITEM_CREATE_REQUEST,
    GROCERY_ITEM_CREATE_SUCCESS,
    GROCERY_ITEM_UPDATE_REQUEST,
    GROCERY_ITEM_UPDATE_SUCCESS,
    GROCERY_ITEM_UPDATE_FAIL,
    GROCERY_ITEM_UPDATE_ACKNOWLEDGE,
    GROCERY_ITEM_DELETE_REQUEST,
    GROCERY_ITEM_DELETE_SUCCESS,
    GROCERY_ITEM_DELETE_FAIL,
    GROCERY_ITEM_DELETE_ACKNOWLEDGE,
    GROCERY_ITEM_DELETE_ALL_FAIL,
    GROCERY_ITEM_DELETE_ALL_REQUEST,
    GROCERY_ITEM_DELETE_ALL_SUCCESS,
    GROCERY_ITEM_DELETE_ALL_ACKNOWLEDGE,
    GROCERY_UNDO_LIST_SUCCESS, GROCERY_UNDO_LIST_FAIL
} from "../constants/groceryConstants";

export const groceryUndoListReducer = (state={}, action) => {
    switch(action.type) {
        case GROCERY_UNDO_LIST_SUCCESS:
            return { loading: false, undoList: action.payload };
        case GROCERY_UNDO_LIST_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const groceryFavoritesGetReducer = (state={}, action) => {
    switch(action.type) {
        case GROCERY_FAVORITE_GET_REQUEST:
            return { loading: true };
        case GROCERY_FAVORITE_GET_SUCCESS:
            return { loading: false, groceryFavorites: action.payload[0], length: action.length };
        case GROCERY_FAVORITE_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const groceryFavoritesCreateReducer = (state={}, action) => {
    switch(action.type) {
        case GROCERY_FAVORITE_CREATE_REQUEST:
            return { loading: true };
        case GROCERY_FAVORITE_CREATE_SUCCESS:
            return { loading: false, success: true };
        case GROCERY_FAVORITE_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_FAVORITE_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryFavoritesUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case GROCERY_FAVORITE_UPDATE_REQUEST:
            return { loading: true };
        case GROCERY_FAVORITE_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case GROCERY_FAVORITE_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_FAVORITE_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryFavoritesDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case GROCERY_FAVORITE_DELETE_REQUEST:
            return {  };
        case GROCERY_FAVORITE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case GROCERY_FAVORITE_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_FAVORITE_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryFavoritesDeleteAllReducer = (state = {}, action) => {
    switch(action.type) {
        case GROCERY_FAVORITE_DELETE_ALL_REQUEST:
            return { loading: true };
        case GROCERY_FAVORITE_DELETE_ALL_SUCCESS:
            return { loading: false, success: true };
        case GROCERY_FAVORITE_DELETE_ALL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_FAVORITE_DELETE_ALL_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryItemGetReducer = (state={}, action) => {
    switch(action.type) {
        case GROCERY_ITEM_GET_REQUEST:
            return { loading: true };
        case GROCERY_ITEM_GET_SUCCESS:
            return { loading: false, groceryItems: action.payload, length: action.length };
        case GROCERY_ITEM_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const groceryItemCreateReducer = (state={}, action) => {
    switch(action.type) {
        case GROCERY_ITEM_CREATE_REQUEST:
            return { loading: true };
        case GROCERY_ITEM_CREATE_SUCCESS:
            return { loading: false, success: true, groceryItem: action.payload };
        case GROCERY_ITEM_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_ITEM_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryItemUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case GROCERY_ITEM_UPDATE_REQUEST:
            return { loading: true };
        case GROCERY_ITEM_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case GROCERY_ITEM_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_ITEM_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryItemDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case GROCERY_ITEM_DELETE_REQUEST:
            return {  };
        case GROCERY_ITEM_DELETE_SUCCESS:
            return { loading: false, success: true, id: action.payload };
        case GROCERY_ITEM_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_ITEM_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const groceryItemDeleteAllReducer = (state = {}, action) => {
    switch(action.type) {
        case GROCERY_ITEM_DELETE_ALL_REQUEST:
            return {  };
        case GROCERY_ITEM_DELETE_ALL_SUCCESS:
            return { loading: false, success: true };
        case GROCERY_ITEM_DELETE_ALL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case GROCERY_ITEM_DELETE_ALL_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}