import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import { customSwal } from '../CustomSwal'
import { deleteReminder, updateReminder } from '../../redux-components/actions/reminderActions';

const ReminderItem = ({title, due, content, reminderID, messageSent, userAdmin, recurringType}) => {
    const dispatch = useDispatch();

    const editHandler = async() => {
        let dueDate = moment(new Date(due)).format("YYYY-MM-DD");
        let dueTime = moment(new Date(due)).format("HH:mm");
        const { value: formValues } = await customSwal.fire({
            title: `Edit Reminder`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Title</label>
                        <input value='${title}' id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Due Date</label>
                        <div class='flex flex-row space-x-2 text-black'>
                            <input id='newDate' value='${dueDate}' class='p-2 rounded-md bg-zinc-300 flex flex-grow' type='date' />
                            <input id='newTime' value='${dueTime}' value class='p-2 rounded-md bg-zinc-300 flex flex-grow' type='time' />
                        </div>
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Recurring Type</label>
                        <select id='recurringType' value='${recurringType}' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-zinc-300' value='none'>None</option>
                            <option class='bg-zinc-300' value='hourly'>Hourly</option>
                            <option class='bg-zinc-300' value='daily'>Daily</option>
                            <option class='bg-zinc-300' value='weekly'>Weekly</option>
                            <option class='bg-zinc-300' value='monthly'>Monthly</option>
                            <option class='bg-zinc-300' value='yearly'>Yearly</option>
                        </select>
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Description</label>
                        <textarea id='newDescription' rows="5" class='p-2 rounded-md bg-zinc-300'>${content}</textarea>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                let formDate = document.getElementById('newDate').value
                let formTime = document.getElementById('newTime').value
                let newDate;
                if(formDate !== "" && formTime !== "") {
                    newDate = new Date(formDate + " " + formTime).toISOString();
                } else if(formDate !== "" && formTime === "") {
                    newDate = new Date(formDate).toISOString();
                } else {
                    newDate = undefined;
                }

                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newDescription').value,
                    newDate,
                    document.getElementById('recurringType').value
                ]
            },
            didOpen: () => {
                document.getElementById('recurringType').value = recurringType; // Set the default value
            }
        })

        if(formValues) {
            //console.log(formValues);
            dispatch(updateReminder(reminderID, formValues[0], formValues[2], formValues[1], formValues[3]));
        }
    }

    const deleteHandler = async() => {
        customSwal.fire({
            title: `Removing ${title}`,
            html:
                `<div class='flex flex-col'>
                    <p>Are you sure you want to delete this reminder?</p>
                </div>`,
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if(result.isDenied) {
                dispatch(deleteReminder(reminderID));
            }
        })
    }

    const completeHandler = async() => {
        dispatch(deleteReminder(reminderID));
    }

    const editMode = useSelector((state) => state.editMode);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    const parseDate = new Date(due);
    const today = new Date();
    const markdownClasses = `prose-headings:m-0 prose-p:m-0 prose-p:whitespace-pre-wrap prose-hr:m-0 prose-ul:m-0 prose-li:m-0 prose-ol:m-0 prose-hr:border-sky-600 prose-headings:text-sky-600 prose-li:whitespace-normal`;

  return (
    <div className={`${parseDate > today ? "bg-blue-100" : "bg-rose-100"} flex flex-col text-start p-2 rounded-lg text-black`}>
        <div className='flex flex-row items-center p-2 flex-auto space-x-2'>
            <div className='font-bold flex flex-grow w-5/6'>
                <p dangerouslySetInnerHTML={{ __html: title }}></p>
            </div>
            <div className='flex flex-grow'></div>
            {
                due === undefined
                ? ""
                : (
                    <div className={`${parseDate > today ? "bg-blue-500" : "bg-red-500"} text-center w-full rounded-full text-white p-1.5 text-sm`}>
                        <p>{parseDate.toLocaleString().split(',')[0]}</p>
                        <p>{parseDate.toLocaleString().split(',')[1]}</p>
                    </div>
                )
            }
            <div onClick={editHandler} className={` ${edit ? "flex" : "hidden"} items-center justify-center bg-blue-500 hover:bg-blue-700 text-white cursor-pointer p-2 rounded-full h-7 w-7`}>
                <FontAwesomeIcon icon="fas fa-pen" />
            </div>
            <div onClick={deleteHandler} className={` ${edit ? "flex" : "hidden"} flex items-center justify-center bg-red-500 hover:bg-red-700 text-white cursor-pointer p-2 rounded-full h-7 w-7`}>
                <FontAwesomeIcon icon="fas fa-trash" />
            </div>
        </div>
        <div className='flex flex-row items-center flex-auto border-t border-slate-500'>
            <article style={{maxHeight: "150px"}} className={`${markdownClasses} tab-scroll leading-none text-black font-sans flex-grow overflow-auto text-left`}>
                <ReactMarkdown linkTarget={"_blank"} children={content} remarkPlugins={[remarkGfm]} className='marker:text-sky-600 prose prose-sky prose-sm p-6'></ReactMarkdown>
            </article>
            <div onClick={completeHandler} className='flex items-center justify-center bg-green-500 hover:bg-green-700 text-white cursor-pointer p-2 rounded-full'>
                <FontAwesomeIcon icon="fas fa-check"/>
            </div>
        </div>
        {userAdmin && <div className='h-4 text-sm cursor-default text-transparent hover:text-black'>
            Message Sent: {messageSent.toString()}
        </div>}
    </div>
  )
}

export default ReminderItem