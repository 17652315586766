/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import EditButton from "../../components/Layout/EditButton";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import ChecklistSortable from "../../components/Admin/Checklist/ChecklistSortable";
import {loadChecklistCategories} from "../../redux-components/actions/checklistActions";
import Loading from "../../components/Layout/Loading";
import ChecklistCategoryCreate from "../../components/Admin/Checklist/ChecklistCategoryCreate";

const Checklist = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            history('/login?next=/checklist');
        } else if(!userInfo.isAdmin) {
            history('/')
        }
    }, [userInfo, history])

    const editMode = useSelector((state) => state.editMode);
    const checklistCategoryGet = useSelector(state => state.checklistCategoryGet);
    const { loading: checklistCategoryGetLoading, error: checklistCategoryGetError, checklistCategorys, first } = checklistCategoryGet;
    const checklistItemCreate = useSelector(state => state.checklistItemCreate);
    const { success: checklistItemCreateSuccess, error: checklistItemCreateError } = checklistItemCreate;
    const checklistItemDelete = useSelector(state => state.checklistItemDelete);
    const { success: checklistItemDeleteSuccess, error: checklistItemDeleteError } = checklistItemDelete;
    const checklistCategoryCreate = useSelector(state => state.checklistCategoryCreate);
    const { success: checklistCategoryCreateSuccess, error: checklistCategoryCreateError } = checklistCategoryCreate;
    const checklistCategoryUpdate = useSelector(state => state.checklistCategoryUpdate);
    const { success: checklistCategoryUpdateSuccess, error: checklistCategoryUpdateError } = checklistCategoryUpdate;
    const checklistCategoryDelete = useSelector(state => state.checklistCategoryDelete);
    const { success: checklistCategoryDeleteSuccess, error: checklistCategoryDeleteError } = checklistCategoryDelete;
    const checklistItemUpdate = useSelector(state => state.checklistItemUpdate);
    const { success: checklistItemUpdateSuccess, error: checklistItemUpdateError } = checklistItemUpdate;

    const [edit, setEdit] = useState(false);
    const [categories, setCategories] = useState([]);
    const [current, setCurrent] = useState(null);
    const [checklistOpenFromLocalStorage, setChecklistOpenFromLocalStorage] = useState(localStorage.getItem('checklistOpen'));

    useEffect(() => {
        setCategories(checklistCategorys);
        if(localStorage.getItem('checklistOpen') === null || !localStorage.getItem('checklistOpen')) localStorage.setItem('checklistOpen', first)
        setCurrent(localStorage.getItem('checklistOpen'))
    }, [first, checklistCategorys]);

    useEffect(() => {
        setCurrent(checklistOpenFromLocalStorage)
    }, [checklistOpenFromLocalStorage]);

    useEffect(() => {
        async function fetchCategories() {
            await dispatch(loadChecklistCategories());
        }
        fetchCategories();
    }, [dispatch, userInfo, checklistItemCreateSuccess, checklistItemDeleteSuccess, checklistCategoryCreateSuccess, checklistCategoryUpdateSuccess, checklistCategoryDeleteSuccess]);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    return (
        <>
            <Layout title={"My Checklists"} col>
                <div className={'flex flex-col space-y-2 w-full md:w-2/3 h-full mx-auto'}>
                    {
                        (categories?.length > 0) ?
                            categories?.map((lists) =>
                                <ChecklistSortable key={lists._id} {...lists} current={current} onClick={() => {
                                    const getItem = localStorage.getItem('checklistOpen');

                                    if(getItem === lists._id) {
                                        localStorage.setItem('checklistOpen', null);
                                        setCurrent(null)
                                    } else {
                                        localStorage.setItem('checklistOpen', lists._id)
                                        setCurrent(lists._id)
                                    }
                                }}/>
                            )
                            : <ChecklistCategoryCreate />
                    }
                    {(edit && categories?.length > 0) && (
                        <ChecklistCategoryCreate />
                    )}
                    {checklistCategoryGetLoading && <Loading rounded={'rounded-md'} />}
                </div>
            </Layout>
            <div className='block fixed right-5 bottom-5 z-50 text-white'>
                <EditButton/>
            </div>
        </>
    );
};

export default Checklist;