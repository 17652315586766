export const REMINDER_CATEGORY_GET_REQUEST = "REMINDER_CATEGORY_GET_REQUEST";
export const REMINDER_CATEGORY_GET_SUCCESS = "REMINDER_CATEGORY_GET_SUCCESS";
export const REMINDER_CATEGORY_GET_FAIL = "REMINDER_CATEGORY_GET_FAIL";

export const REMINDER_GET_REQUEST = "REMINDER_GET_REQUEST";
export const REMINDER_GET_SUCCESS = "REMINDER_GET_SUCCESS";
export const REMINDER_GET_FAIL = "REMINDER_GET_FAIL";

export const REMINDER_CATEGORY_CREATE_REQUEST = "REMINDER_CATEGORY_CREATE_REQUEST";
export const REMINDER_CATEGORY_CREATE_SUCCESS = "REMINDER_CATEGORY_CREATE_SUCCESS";
export const REMINDER_CATEGORY_CREATE_FAIL = "REMINDER_CATEGORY_CREATE_FAIL";
export const REMINDER_CATEGORY_CREATE_ACKNOWLEDGE = "REMINDER_CATEGORY_CREATE_ACKNOWLEDGE";

export const REMINDER_CREATE_REQUEST = "REMINDER_CREATE_REQUEST";
export const REMINDER_CREATE_SUCCESS = "REMINDER_CREATE_SUCCESS";
export const REMINDER_CREATE_FAIL = "REMINDER_CREATE_FAIL";
export const REMINDER_CREATE_ACKNOWLEDGE = "REMINDER_CREATE_ACKNOWLEDGE";

export const REMINDER_CATEGORY_UPDATE_REQUEST = "REMINDER_CATEGORY_UPDATE_REQUEST";
export const REMINDER_CATEGORY_UPDATE_SUCCESS = "REMINDER_CATEGORY_UPDATE_SUCCESS";
export const REMINDER_CATEGORY_UPDATE_FAIL = "REMINDER_CATEGORY_UPDATE_FAIL";
export const REMINDER_CATEGORY_UPDATE_ACKNOWLEDGE = "REMINDER_CATEGORY_UPDATE_ACKNOWLEDGE";

export const REMINDER_UPDATE_REQUEST = "REMINDER_UPDATE_REQUEST";
export const REMINDER_UPDATE_SUCCESS = "REMINDER_UPDATE_SUCCESS";
export const REMINDER_UPDATE_FAIL = "REMINDER_UPDATE_FAIL";
export const REMINDER_UPDATE_ACKNOWLEDGE = "REMINDER_UPDATE_ACKNOWLEDGE";

export const REMINDER_CATEGORY_DELETE_REQUEST = "REMINDER_CATEGORY_DELETE_REQUEST";
export const REMINDER_CATEGORY_DELETE_SUCCESS = "REMINDER_CATEGORY_DELETE_SUCCESS";
export const REMINDER_CATEGORY_DELETE_FAIL = "REMINDER_CATEGORY_DELETE_FAIL";
export const REMINDER_CATEGORY_DELETE_ACKNOWLEDGE = "REMINDER_CATEGORY_DELETE_ACKNOWLEDGE";

export const REMINDER_CATEGORY_DELETE_ALL_REQUEST = "REMINDER_CATEGORY_DELETE_ALL_REQUEST";
export const REMINDER_CATEGORY_DELETE_ALL_SUCCESS = "REMINDER_CATEGORY_DELETE_ALL_SUCCESS";
export const REMINDER_CATEGORY_DELETE_ALL_FAIL = "REMINDER_CATEGORY_DELETE_ALL_FAIL";
export const REMINDER_CATEGORY_DELETE_ALL_ACKNOWLEDGE = "REMINDER_CATEGORY_DELETE_ALL_ACKNOWLEDGE";

export const REMINDER_DELETE_REQUEST = "REMINDER_DELETE_REQUEST";
export const REMINDER_DELETE_SUCCESS = "REMINDER_DELETE_SUCCESS";
export const REMINDER_DELETE_FAIL = "REMINDER_DELETE_FAIL";
export const REMINDER_DELETE_ACKNOWLEDGE = "REMINDER_DELETE_ACKNOWLEDGE";

export const REMINDER_CATEGORY_IMPORT_REQUEST = "REMINDER_CATEGORY_IMPORT_REQUEST";
export const REMINDER_CATEGORY_IMPORT_SUCCESS = "REMINDER_CATEGORY_IMPORT_SUCCESS";
export const REMINDER_CATEGORY_IMPORT_FAIL = "REMINDER_CATEGORY_IMPORT_FAIL";
export const REMINDER_CATEGORY_IMPORT_ACKNOWLEDGE = "REMINDER_CATEGORY_IMPORT_ACKNOWLEDGE";

export const REMINDER_CATEGORY_CLEAR_REQUEST = "REMINDER_CATEGORY_CLEAR_REQUEST";
export const REMINDER_CATEGORY_CLEAR_SUCCESS = "REMINDER_CATEGORY_CLEAR_SUCCESS";
export const REMINDER_CATEGORY_CLEAR_FAIL = "REMINDER_CATEGORY_CLEAR_FAIL";
export const REMINDER_CATEGORY_CLEAR_ACKNOWLEDGE = "REMINDER_CATEGORY_CLEAR_ACKNOWLEDGE";