import axios from "axios";
import { REMINDER_CATEGORY_CLEAR_FAIL, REMINDER_CATEGORY_CLEAR_REQUEST, REMINDER_CATEGORY_CLEAR_SUCCESS, REMINDER_CATEGORY_CREATE_FAIL, REMINDER_CATEGORY_CREATE_REQUEST, REMINDER_CATEGORY_CREATE_SUCCESS, REMINDER_CATEGORY_DELETE_ALL_FAIL, REMINDER_CATEGORY_DELETE_ALL_REQUEST, REMINDER_CATEGORY_DELETE_ALL_SUCCESS, REMINDER_CATEGORY_DELETE_FAIL, REMINDER_CATEGORY_DELETE_REQUEST, REMINDER_CATEGORY_DELETE_SUCCESS, REMINDER_CATEGORY_GET_FAIL, REMINDER_CATEGORY_GET_REQUEST, REMINDER_CATEGORY_GET_SUCCESS, REMINDER_CATEGORY_IMPORT_FAIL, REMINDER_CATEGORY_IMPORT_REQUEST, REMINDER_CATEGORY_IMPORT_SUCCESS, REMINDER_CATEGORY_UPDATE_FAIL, REMINDER_CATEGORY_UPDATE_REQUEST, REMINDER_CATEGORY_UPDATE_SUCCESS, REMINDER_CREATE_FAIL, REMINDER_CREATE_REQUEST, REMINDER_CREATE_SUCCESS, REMINDER_DELETE_FAIL, REMINDER_DELETE_REQUEST, REMINDER_DELETE_SUCCESS, REMINDER_GET_FAIL, REMINDER_GET_REQUEST, REMINDER_GET_SUCCESS, REMINDER_UPDATE_FAIL, REMINDER_UPDATE_REQUEST, REMINDER_UPDATE_SUCCESS } from "../constants/reminderConstants";

export const loadCategories = () => async(dispatch, getState) => {
    try {
        dispatch({type: REMINDER_CATEGORY_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/reminder/category/`, config);

        dispatch({type: REMINDER_CATEGORY_GET_SUCCESS, payload: data, length: data.length, first: data[0]._id});

    } catch (error) {
        dispatch({
            type: REMINDER_CATEGORY_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const loadReminders = () => async(dispatch, getState) => {
    try {
        dispatch({type: REMINDER_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/reminder/`, config);

        dispatch({type: REMINDER_GET_SUCCESS, payload: data});

    } catch (error) {
        dispatch({
            type: REMINDER_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const createReminders = (category_id, title, description, reminderDate, recurringType) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/reminder/create/${category_id}`, { title, description, reminderDate, recurringType }, config);

        dispatch({
            type: REMINDER_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CREATE_FAIL,
            payload: message,
        });
    }
}

export const createCategory = (title, color) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CATEGORY_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/reminder/category/create`, { title, color }, config);

        dispatch({
            type: REMINDER_CATEGORY_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CATEGORY_CREATE_FAIL,
            payload: message,
        });
    }
}

export const updateCategory = (id, title) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CATEGORY_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/reminder/category/update/${id}`, { title }, config);

        dispatch({
            type: REMINDER_CATEGORY_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CATEGORY_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const updateReminder = (id, title, reminderDate, description, recurringType) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/reminder/update/${id}`, { title, reminderDate, description, recurringType }, config);

        dispatch({
            type: REMINDER_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const deleteReminder = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/reminder/delete/${id}`, config);

        dispatch({
            type: REMINDER_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_DELETE_FAIL,
            payload: message,
        });
    }
}

export const deleteCategory = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CATEGORY_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/reminder/category/delete/${id}`, config);

        dispatch({
            type: REMINDER_CATEGORY_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CATEGORY_DELETE_FAIL,
            payload: message,
        });
    }
}

export const deleteCategoryAll = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CATEGORY_DELETE_ALL_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/reminder/category/clear/${id}`, config);

        dispatch({
            type: REMINDER_CATEGORY_DELETE_ALL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CATEGORY_DELETE_ALL_FAIL,
            payload: message,
        });
    }
}

export const categoryClear = () => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CATEGORY_CLEAR_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/reminder/category/delete`, config);

        dispatch({
            type: REMINDER_CATEGORY_CLEAR_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CATEGORY_CLEAR_FAIL,
            payload: message,
        });
    }
}

export const adminImportReminder = (parsedJSON) => async(dispatch, getState) => {
    try {
        dispatch({
            type: REMINDER_CATEGORY_IMPORT_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/admin/importReminder`, {parsedJSON}, config);

        dispatch({
            type: REMINDER_CATEGORY_IMPORT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: REMINDER_CATEGORY_IMPORT_FAIL,
            payload: message,
        });
    }
}