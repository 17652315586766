import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EDIT_MODE_FALSE, EDIT_MODE_TRUE } from '../../redux-components/constants/editModeConstants';

const EditButton = () => {

  const dispatch = useDispatch();

  const editMode = useSelector((state) => state.editMode);

  const editModeHandler = () => {
    if(editMode) {
      dispatch({type: EDIT_MODE_FALSE});
    } else {
      dispatch({type: EDIT_MODE_TRUE});
    }
  }

  return (
    <div title='Edit Mode' onClick={editModeHandler} target='_blank' className={`flex items-center justify-center ${editMode ? "text-emerald-400 edit-spinner" : "text-white"} bg-black hover:bg-zinc-700 cursor-pointer p-2 rounded-full h-14 w-14`}>
        <FontAwesomeIcon icon='fa-solid fa-cog' size='2x' />
    </div>
  )
}

export default EditButton