import { EDIT_MODE_FALSE, EDIT_MODE_REQUEST, EDIT_MODE_TRUE } from "../constants/editModeConstants";

export const editModeReducer = (state={}, action) => {
    switch(action.type) {
        case EDIT_MODE_REQUEST:
            return state;
        case EDIT_MODE_TRUE:
            return true;
        case EDIT_MODE_FALSE:
            return false;
        default:
            return state;
    }
}