/* eslint-disable no-unused-vars */

import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {useDispatch, useSelector} from "react-redux";
import {
    createGroceryHeader,
    createGroceryItem,
    deleteAllGroceryItem, loadGroceryFavorites,
    loadGroceryItems, removeGroceryUndoList,
} from "../../redux-components/actions/groceryActions";
import Swal from "sweetalert2";
import {useNavigate} from "react-router";
import Loading from "../../components/Layout/Loading";
import EditButton from "../../components/Layout/EditButton";
import {customDelSwal} from "../../components/CustomSwal";
import {EDIT_MODE_FALSE} from "../../redux-components/constants/editModeConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GrocerySortable from "../../components/Grocery/GrocerySortable";
import GroceryFavoritesList from "../../components/Grocery/GroceryFavoritesList";
import {useSubmit} from "react-router-dom";

const Grocery = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            history('/login?next=/grocery');
        }
    }, [userInfo, history])

    const editMode = useSelector((state) => state.editMode);
    const groceryItemGet = useSelector(state => state.groceryItemGet);
    const { loading: groceryItemGetLoading, error: groceryItemGetError, groceryItems, length } = groceryItemGet;
    const groceryItemCreate = useSelector(state => state.groceryItemCreate);
    const { success: groceryItemCreateSuccess, error: groceryItemCreateError } = groceryItemCreate;
    const groceryUndoList = useSelector(state => state.groceryUndoList);
    const { success: groceryUndoListCreateSuccess, error: groceryUndoListCreateError, undoList } = groceryUndoList;
    const groceryItemUpdate = useSelector(state => state.groceryItemUpdate);
    const { success: groceryItemUpdateSuccess, error: groceryItemUpdateError } = groceryItemUpdate;
    const groceryItemDelete = useSelector(state => state.groceryItemDelete);
    const { success: groceryItemDeleteSuccess, error: groceryItemDeleteError, id: groceryItemDeleteId } = groceryItemDelete;
    const groceryItemDeleteAll = useSelector(state => state.groceryItemDeleteAll);
    const { success: groceryItemDeleteAllSuccess, error: groceryItemDeleteAllError } = groceryItemDeleteAll;

    const groceryFavoritesGet = useSelector(state => state.groceryFavoritesGet);
    const { loading: groceryFavoritesGetLoading, error: groceryFavoritesGetError, groceryFavorites, length: favoritesLength } = groceryFavoritesGet;
    const groceryFavoritesCreate = useSelector((state) => state.groceryFavoritesCreate);
    const { success: successFavoritesCreate } = groceryFavoritesCreate;
    const groceryFavoritesDelete = useSelector((state) => state.groceryFavoritesDelete);
    const { success: successFavoritesDelete } = groceryFavoritesDelete;

    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState("");
    const [header, setHeader] = useState("");
    // eslint-disable-next-line
    const [state, setState] = useState([]);
    const [undoListState, setUndoListState] = useState([]);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

    useEffect(() => {
        dispatch(loadGroceryItems());
        dispatch(loadGroceryFavorites());

        // Set up visibility change listener
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                dispatch(loadGroceryItems());
                dispatch(loadGroceryFavorites());
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Clean up the event listener
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [dispatch]);

    useEffect(() => {
        setUndoListState(undoList);
    }, [undoList]);

    useEffect(() => {
        setState(groceryItems);
    }, [groceryItems])

    useEffect(() => {
        async function fetchFavorites() {
            await dispatch(loadGroceryFavorites());
        }
        fetchFavorites();
    }, [dispatch, groceryItems, successFavoritesCreate, successFavoritesDelete]);

    useEffect(() => {
        async function fetchGrocery() {
            await dispatch(loadGroceryItems());
        }
        fetchGrocery();
    }, [dispatch, userInfo, groceryItemDeleteAllSuccess]);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    const updateAutocompleteSuggestions = (input) => {
        if(input.length === 0) {
            setAutocompleteSuggestions([]);
            return;
        }

        const filteredSuggestions = groceryFavorites?.favorites.filter(favorite =>
            favorite.toLowerCase().startsWith(input.toLowerCase())
        );
        setAutocompleteSuggestions(filteredSuggestions);
    }

    const handleSuggestionSelect = (suggestion) => {
        setItem(suggestion);
        setAutocompleteSuggestions([]);
        // Use setTimeout to ensure this runs after state updates
        setTimeout(() => {
            createHandler(null, suggestion);
        }, 0);
    };

    const createHandler = async(e, suggestedItem = null) => {
        if(e) e.preventDefault();
        try {
            const itemToAdd = suggestedItem || item;
            if(itemToAdd === '') {
                throw new Error('Item cannot be empty')
            }
            dispatch(createGroceryItem(itemToAdd));
            setItem("");
            setAutocompleteSuggestions([]);
        } catch (e) {
            Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            });
        }
    };

    const createHeaderHandler = async(e) => {
        e.preventDefault();
        try {
            if(header === '') {
                throw new Error('Item cannot be empty')
            }
            dispatch(createGroceryHeader(header));
            setHeader("");
        } catch (e) {
            Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            });
        }
    }

    const deleteAllItems = async() => {
        customDelSwal.fire({
            title: `Are you sure you want to delete all items?`,
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Delete all items',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if(result.isDenied) {
                //delete all reminders
                dispatch(deleteAllGroceryItem());
                dispatch({type: EDIT_MODE_FALSE});
            } else if(result.isConfirmed) {

            }
        })
    }

    const refreshHandler = async() => {
        dispatch(loadGroceryItems());
    }

    function undoRecent() {
        if(undoListState.length > 0) {
            const last = undoListState.length-1;
            dispatch(createGroceryItem(undoListState[last].description))
            dispatch(removeGroceryUndoList());
        }
    }

    return (
        <>
        <Layout title={"Grocery List"} col>
            <div className={'flex flex-col w-full md:w-2/3 h-full mx-auto'}>
                <div className={`flex bg-white space-y-2 touch-manipulation flex-col mb-2 p-2 text-black rounded-md w-full content-center justify-center items-center`}>
                    <form onSubmit={createHandler} className={'flex flex-row w-full space-x-2'}>
                        <button title={'Refresh List'} onClick={refreshHandler} type={'button'} className={'bg-white border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300 w-10'}>
                            <FontAwesomeIcon icon="fas fa-refresh"/>
                        </button>
                        <button title={'Undo Action'} onClick={undoRecent} type={'button'} className={`${undoListState?.length > 0 ? "bg-white" : "bg-zinc-500"} border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300 w-10`}>
                            <FontAwesomeIcon icon="fas fa-undo"/>
                        </button>
                        <div className="relative flex flex-grow">
                            <input
                                onChange={(e) => {
                                    setItem(e.target.value);
                                    updateAutocompleteSuggestions(e.target.value);
                                }}
                                onBlur={() => setTimeout(() => setAutocompleteSuggestions([]), 100)}
                                value={item}
                                type="text"
                                className="bg-white border-black border-2 text-black p-2 text-md flex flex-grow rounded-md"
                            />
                            {autocompleteSuggestions?.length > 0 && (
                                <ul className="absolute z-50 w-full bg-white border-2 border-black mt-12 rounded-md shadow-lg">
                                    {autocompleteSuggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            className={`px-4 py-2 hover:bg-zinc-400 cursor-pointer ${index !== 0 ? "border-t-2 border-black" : ""}`}
                                            onMouseDown={() => {
                                                handleSuggestionSelect(suggestion);
                                            }}
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <button type={'submit'}
                                className={'bg-white border-black border-2 text-black p-2 rounded-md hover:bg-zinc-300'}>Add
                            Item
                        </button>
                        {
                            edit ? (<button onClick={deleteAllItems} type={'button'}
                                            className={'bg-red-500 text-white p-2 border-black border-2 rounded-md hover:bg-red-300'}>Clear
                                List</button>) : ""
                        }
                    </form>
                    {edit && <form onSubmit={createHeaderHandler}
                                   className={'flex flex-row content-center items-center justify-center space-x-2 mb-2 w-full mx-auto'}>
                        <input onChange={(e) => setHeader(e.target.value)} value={header} type={'text'}
                               className={'bg-white border-black border-2 text-black p-2 text-md flex flex-grow rounded-md'}/>
                        <button type={'submit'}
                                className={'border-black border-2 bg-white text-black p-2 rounded-md hover:bg-zinc-300'}>Add Header</button>
                    </form>}
                    <GroceryFavoritesList/>
                </div>

                <div className={`space-y-2 flex flex-col relative content-center items-center justify-center w-full`}>

                    {groceryItemGetLoading && <Loading rounded={"rounded-lg"} opacity={"bg-opacity-50"} />}
                    <GrocerySortable />
                </div>
            </div>
        </Layout>
        <div className='block fixed right-5 bottom-5 z-50 text-white'>
            <EditButton/>
        </div>
        </>
    );
};

export default Grocery;
