import Swal from "sweetalert2"

export const customSwal = Swal.mixin({
    customClass: {
        confirmButton: 'bg-green-500 hover:bg-green-300 px-4 py-2 text-lg text-white rounded-md ml-2',
        cancelButton: 'bg-blue-500 hover:bg-blue-300 px-4 py-2 text-lg text-white rounded-md',
        denyButton: 'bg-red-500 hover:bg-red-300 px-4 py-2 text-lg text-white rounded-md ml-2',
    },
    buttonsStyling: false
})

export const customDelSwal = Swal.mixin({
    customClass: {
        confirmButton: 'bg-red-500 hover:bg-red-300 px-4 py-2 text-lg text-white rounded-md ml-2',
        cancelButton: 'bg-blue-500 hover:bg-blue-300 px-4 py-2 text-lg text-white rounded-md',
        denyButton: 'bg-red-500 hover:bg-red-300 px-4 py-2 text-lg text-white rounded-md ml-2',
    },
    buttonsStyling: false
})

export const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})