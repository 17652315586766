import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Toast} from "../../CustomSwal";
import {deleteChecklistItem, updateChecklistItem} from "../../../redux-components/actions/checklistActions";

const ChecklistItem = props => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState(props.description || "");
    const [checked, setChecked] = useState(props.checked || false);
    const [edit, setEdit] = useState(false);

    const editMode = useSelector((state) => state.editMode);
    const checklistItemUpdateState = useSelector((state) => state.checklistItemUpdate);
    const { success: successUpdate } = checklistItemUpdateState;

    useEffect(() => {
        let debounce = setTimeout(() => {
            updateHandler();
        }, 1000);
        return () => {
            clearTimeout(debounce);
        }
    }, [description]);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    const checkedHandler = () => {
        try {
            dispatch(updateChecklistItem(props._id, description, !checked, props.position))

            setChecked(!checked)
            Toast.fire({
                icon: 'success',
                title: `Item ${checked ? "Unchecked" : "Checked"}`
            })
        } catch (e) {
            Toast.fire({
                icon: 'error',
                title: e.message
            })
        }
    }


    const updateHandler = () => {
        if(props.description !== description) {
            try {
                dispatch(updateChecklistItem(props._id, description, checked, props.position));

                if(!successUpdate) {
                    throw new Error('Error Updating Item');
                }

                Toast.fire({
                    icon: 'success',
                    title: 'Item updated'
                })
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                })
            }
        }
    }
    function deleteHandler() {
        try {
            dispatch(deleteChecklistItem(props._id));

            Toast.fire({
                icon: 'success',
                title: 'Item deleted'
            })
        } catch (e) {
            Toast.fire({
                icon: 'error',
                title: e.message
            })
        }
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: props._id})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }


    return (
        <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={`${checked ? "bg-blue-200" : "bg-gray-200"} flex space-x-2 touch-manipulation flex-row mb-2 p-4 text-black rounded-md w-full content-center justify-center items-center`}>
                <div onClick={deleteHandler} className={`${edit ? "flex" : "hidden"} h-8 w-8 rounded-full items-center justify-center text-red-500 cursor-pointer hover:text-red-300`}>
                    <FontAwesomeIcon icon={'fas fa-trash'} size={'xl'} />
                </div>
                <input onChange={(e) => setDescription(e.target.value)} type={'text'} value={description} className={`${checked ? "bg-blue-200" : "bg-gray-200"} flex flex-grow text-center placeholder-black content-center items-center justify-center w-full p-2 overflow-auto max-h-36 empty-scroll`} />
                <div onClick={checkedHandler} className={`${checked ? "text-white" : "text-black hover:text-zinc-800"} flex items-center h-8 w-8 justify-center bg-black hover:bg-zinc-800 cursor-pointer p-2 rounded-md`}>
                    <FontAwesomeIcon icon="fas fa-check"/>
                </div>
            </div>
        </>
    );
};

export default ChecklistItem;