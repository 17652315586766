import axios from "axios";
import {
    CHECKLIST_CATEGORY_CREATE_FAIL,
    CHECKLIST_CATEGORY_CREATE_REQUEST,
    CHECKLIST_CATEGORY_CREATE_SUCCESS,
    CHECKLIST_CATEGORY_DELETE_FAIL,
    CHECKLIST_CATEGORY_DELETE_REQUEST,
    CHECKLIST_CATEGORY_DELETE_SUCCESS,
    CHECKLIST_CATEGORY_GET_FAIL,
    CHECKLIST_CATEGORY_GET_REQUEST,
    CHECKLIST_CATEGORY_GET_SUCCESS,
    CHECKLIST_CATEGORY_UPDATE_FAIL,
    CHECKLIST_CATEGORY_UPDATE_REQUEST,
    CHECKLIST_CATEGORY_UPDATE_SUCCESS,
    CHECKLIST_ITEM_CREATE_FAIL,
    CHECKLIST_ITEM_CREATE_REQUEST,
    CHECKLIST_ITEM_CREATE_SUCCESS,
    CHECKLIST_ITEM_DELETE_FAIL,
    CHECKLIST_ITEM_DELETE_REQUEST,
    CHECKLIST_ITEM_DELETE_SUCCESS,
    CHECKLIST_ITEM_UPDATE_FAIL,
    CHECKLIST_ITEM_UPDATE_REQUEST,
    CHECKLIST_ITEM_UPDATE_SUCCESS
} from "../constants/checklistConstants";

export const loadChecklistCategories = () => async(dispatch, getState) => {
    try {
        dispatch({type: CHECKLIST_CATEGORY_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/checklist/categories`, config);

        dispatch({type: CHECKLIST_CATEGORY_GET_SUCCESS, payload: data, first: data[0]._id});

    } catch (error) {
        dispatch({
            type: CHECKLIST_CATEGORY_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const createChecklistItem = (checklist_category, description) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CHECKLIST_ITEM_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/checklist/items/create/${checklist_category}`, { description }, config);

        dispatch({
            type: CHECKLIST_ITEM_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: CHECKLIST_ITEM_CREATE_FAIL,
            payload: message,
        });
    }
}

export const updateChecklistItem = (id, description, checked, position) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CHECKLIST_ITEM_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/checklist/items/update/${id}`, { description, checked, position }, config);

        dispatch({
            type: CHECKLIST_ITEM_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: CHECKLIST_ITEM_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const deleteChecklistItem = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CHECKLIST_ITEM_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/checklist/items/delete/${id}`, config);

        dispatch({
            type: CHECKLIST_ITEM_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: CHECKLIST_ITEM_DELETE_FAIL,
            payload: message,
        });
    }
}

export const createChecklistCategory = (title, color) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CHECKLIST_CATEGORY_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/checklist/categories/create`, { title, color }, config);

        dispatch({
            type: CHECKLIST_CATEGORY_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: CHECKLIST_CATEGORY_CREATE_FAIL,
            payload: message,
        });
    }
}

export const updateChecklistCategory = (id, title, color) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CHECKLIST_CATEGORY_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/checklist/categories/update/${id}`, { title, color }, config);

        dispatch({
            type: CHECKLIST_CATEGORY_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: CHECKLIST_CATEGORY_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const deleteChecklistCategory = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: CHECKLIST_CATEGORY_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/checklist/categories/delete/${id}`, config);

        dispatch({
            type: CHECKLIST_CATEGORY_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: CHECKLIST_CATEGORY_DELETE_FAIL,
            payload: message,
        });
    }
}