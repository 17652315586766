import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import axios from "axios";
import background from '../../img/background.jpg';

const BackgroundImage = ({children}) => {
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const [photo, setPhoto] = useState("")
    useEffect(() => {
        async function getPhoto() {
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            }

            const { data } = await axios.post(`/api/background`, {query: userInfo?.settings?.bgQuery ?? 'nature'}, config);
            setPhoto(data.url);
        }
        getPhoto();
    }, [userInfo]);

    return (
        <div style={{backgroundImage: userInfo ? `url(${photo})` : `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}} className='mx-auto flex flex-col min-h-screen overflow-hidden sm:break-words md:break-normal '>
            {children}
        </div>
    );
};

export default BackgroundImage;