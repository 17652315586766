export const GROCERY_FAVORITE_GET_REQUEST = "GROCERY_FAVORITE_GET_REQUEST";
export const GROCERY_FAVORITE_GET_SUCCESS = "GROCERY_FAVORITE_GET_SUCCESS";
export const GROCERY_FAVORITE_GET_FAIL = "GROCERY_FAVORITE_GET_FAIL";
export const GROCERY_FAVORITE_CREATE_REQUEST = "GROCERY_FAVORITE_CREATE_REQUEST";
export const GROCERY_FAVORITE_CREATE_SUCCESS = "GROCERY_FAVORITE_CREATE_SUCCESS";
export const GROCERY_FAVORITE_CREATE_FAIL = "GROCERY_FAVORITE_CREATE_FAIL";
export const GROCERY_FAVORITE_CREATE_ACKNOWLEDGE = "GROCERY_FAVORITE_CREATE_ACKNOWLEDGE";
export const GROCERY_FAVORITE_UPDATE_REQUEST = "GROCERY_FAVORITE_UPDATE_REQUEST";
export const GROCERY_FAVORITE_UPDATE_SUCCESS = "GROCERY_FAVORITE_UPDATE_SUCCESS";
export const GROCERY_FAVORITE_UPDATE_FAIL = "GROCERY_FAVORITE_UPDATE_FAIL";
export const GROCERY_FAVORITE_UPDATE_ACKNOWLEDGE = "GROCERY_FAVORITE_UPDATE_ACKNOWLEDGE";
export const GROCERY_FAVORITE_DELETE_REQUEST = "GROCERY_FAVORITE_DELETE_REQUEST";
export const GROCERY_FAVORITE_DELETE_SUCCESS = "GROCERY_FAVORITE_DELETE_SUCCESS";
export const GROCERY_FAVORITE_DELETE_FAIL = "GROCERY_FAVORITE_DELETE_FAIL";
export const GROCERY_FAVORITE_DELETE_ACKNOWLEDGE = "GROCERY_FAVORITE_DELETE_ACKNOWLEDGE";
export const GROCERY_FAVORITE_DELETE_ALL_REQUEST = "GROCERY_FAVORITE_DELETE_ALL_REQUEST";
export const GROCERY_FAVORITE_DELETE_ALL_SUCCESS = "GROCERY_FAVORITE_DELETE_ALL_SUCCESS";
export const GROCERY_FAVORITE_DELETE_ALL_FAIL = "GROCERY_FAVORITE_DELETE_ALL_FAIL";
export const GROCERY_FAVORITE_DELETE_ALL_ACKNOWLEDGE = "GROCERY_FAVORITE_DELETE_ALL_ACKNOWLEDGE";
export const GROCERY_ITEM_GET_REQUEST = "GROCERY_ITEM_GET_REQUEST";
export const GROCERY_ITEM_GET_SUCCESS = "GROCERY_ITEM_GET_SUCCESS";
export const GROCERY_ITEM_GET_FAIL = "GROCERY_ITEM_GET_FAIL";
export const GROCERY_ITEM_CREATE_REQUEST = "GROCERY_ITEM_CREATE_REQUEST";
export const GROCERY_ITEM_CREATE_SUCCESS = "GROCERY_ITEM_CREATE_SUCCESS";
export const GROCERY_ITEM_CREATE_FAIL = "GROCERY_ITEM_CREATE_FAIL";
export const GROCERY_ITEM_CREATE_ACKNOWLEDGE = "GROCERY_ITEM_CREATE_ACKNOWLEDGE";
export const GROCERY_ITEM_UPDATE_REQUEST = "GROCERY_ITEM_UPDATE_REQUEST";
export const GROCERY_ITEM_UPDATE_SUCCESS = "GROCERY_ITEM_UPDATE_SUCCESS";
export const GROCERY_ITEM_UPDATE_FAIL = "GROCERY_ITEM_UPDATE_FAIL";
export const GROCERY_ITEM_UPDATE_ACKNOWLEDGE = "GROCERY_ITEM_UPDATE_ACKNOWLEDGE";
export const GROCERY_ITEM_DELETE_REQUEST = "GROCERY_ITEM_DELETE_REQUEST";
export const GROCERY_ITEM_DELETE_SUCCESS = "GROCERY_ITEM_DELETE_SUCCESS";
export const GROCERY_ITEM_DELETE_FAIL = "GROCERY_ITEM_DELETE_FAIL";
export const GROCERY_ITEM_DELETE_ACKNOWLEDGE = "GROCERY_ITEM_DELETE_ACKNOWLEDGE";
export const GROCERY_ITEM_DELETE_ALL_REQUEST = "GROCERY_ITEM_DELETE_ALL_REQUEST";
export const GROCERY_ITEM_DELETE_ALL_SUCCESS = "GROCERY_ITEM_DELETE_ALL_SUCCESS";
export const GROCERY_ITEM_DELETE_ALL_FAIL = "GROCERY_ITEM_DELETE_ALL_FAIL";
export const GROCERY_ITEM_DELETE_ALL_ACKNOWLEDGE = "GROCERY_ITEM_DELETE_ALL_ACKNOWLEDGE";
export const GROCERY_UNDO_LIST_REQUEST = "GROCERY_UNDO_LIST_REQUEST"
export const GROCERY_UNDO_LIST_SUCCESS = "GROCERY_UNDO_LIST_SUCCESS"
export const GROCERY_UNDO_LIST_FAIL = "GROCERY_UNDO_LIST_FAIL"