import { NOTEPAD_CREATE_ACKNOWLEDGE, NOTEPAD_CREATE_FAIL, NOTEPAD_CREATE_REQUEST, NOTEPAD_CREATE_SUCCESS, NOTEPAD_DELETE_ACKNOWLEDGE, NOTEPAD_DELETE_FAIL, NOTEPAD_DELETE_REQUEST, NOTEPAD_DELETE_SUCCESS, NOTEPAD_GET_FAIL, NOTEPAD_GET_REQUEST, NOTEPAD_GET_SUCCESS, NOTEPAD_UPDATE_ACKNOWLEDGE, NOTEPAD_UPDATE_FAIL, NOTEPAD_UPDATE_REQUEST, NOTEPAD_UPDATE_SUCCESS } from "../constants/notepadConstants"
import { USER_LOGOUT } from "../constants/userConstants";

export const notepadGetReducer = (state={}, action) => {
    switch(action.type) {
        case NOTEPAD_GET_REQUEST:
            return { loading: true };
        case NOTEPAD_GET_SUCCESS:
            return { loading: false, notepads: action.payload, length: action.length };
        case NOTEPAD_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const notepadUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case NOTEPAD_UPDATE_REQUEST:
            return { };
        case NOTEPAD_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case NOTEPAD_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case NOTEPAD_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const notepadCreateReducer = (state = {}, action) => {
    switch(action.type) {
        case NOTEPAD_CREATE_REQUEST:
            return { loading: true };
        case NOTEPAD_CREATE_SUCCESS:
            return { loading: false, success: true, error: "", newNotepad: action.payload };
        case NOTEPAD_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case NOTEPAD_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const notepadDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case NOTEPAD_DELETE_REQUEST:
            return { loading: true };
        case NOTEPAD_DELETE_SUCCESS:
            return { loading: false, success: true, error: "" };
        case NOTEPAD_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case NOTEPAD_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}
