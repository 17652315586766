import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import AccountSettings from "../../components/User-Management/AccountSettings";

const Settings = () => {
    const history = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            history('/login?next=/settings');
        }
    }, [userInfo, history])

    return (
        <div className='items-center justify-center flex flex-col text-center mx-auto'>
            <AccountSettings />
        </div>
    );
};

export default Settings;