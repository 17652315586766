import React from 'react';
import {Link} from "react-router-dom";

const Error = () => {
    return (
        <div className='items-center justify-center flex text-center mx-auto'>
            <div className='flex flex-col backdrop-filter backdrop-blur-md bg-black bg-opacity-40 p-4 rounded-lg'>
                <div>
                    <h1 className='text-2xl md:text-4xl lg:text-6xl font-bold text-white p-8'>404 - Page Not Found</h1>
                    <Link to={'/dashboard'} className={'text-lg md:text-xl lg:text-2xl font-bold text-white hover:underline'}>Return to dashboard</Link>
                </div>
            </div>
        </div>
    );
};

export default Error;
