import axios from "axios";
import { SHORTCUT_CREATE_FAIL, SHORTCUT_CREATE_REQUEST, SHORTCUT_CREATE_SUCCESS, SHORTCUT_DELETE_FAIL, SHORTCUT_DELETE_REQUEST, SHORTCUT_DELETE_SUCCESS, SHORTCUT_GET_FAIL, SHORTCUT_GET_REQUEST, SHORTCUT_GET_SUCCESS, SHORTCUT_UPDATE_FAIL, SHORTCUT_UPDATE_REQUEST, SHORTCUT_UPDATE_SUCCESS } from "../constants/shortcutConstants";

export const loadShortcuts = () => async(dispatch, getState) => {
    try {
        dispatch({type: SHORTCUT_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/shortcut/`, config);

        dispatch({type: SHORTCUT_GET_SUCCESS, payload: data, length: data.length});

    } catch (error) {
        dispatch({
            type: SHORTCUT_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const createShortcutAction = (title, link, icon, color, isExpandable) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/shortcut/create`, { title, link, icon, color, isExpandable }, config);

        dispatch({
            type: SHORTCUT_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_CREATE_FAIL,
            payload: message,
        });
    }
}

export const createKiwiShortcutAction = (classOneTitle, classOneLink, classOneSyl, classTwoTitle, classTwoLink, classTwoSyl) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/admin/kiwi`, { classOneTitle, classOneLink, classOneSyl, classTwoTitle, classTwoLink, classTwoSyl }, config);

        dispatch({
            type: SHORTCUT_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_CREATE_FAIL,
            payload: message,
        });
    }
}


export const createExpandableShortcutAction = (id, title, link, icon, color) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.post(`/api/shortcut/expandable/${id}`, { title, link, icon, color }, config);

        dispatch({
            type: SHORTCUT_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_CREATE_FAIL,
            payload: message,
        });
    }
}

export const updateShortcutAction = (id, title, link, icon, color, isExpandable) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/shortcut/${id}`, { title, link, icon, color, isExpandable }, config);

        dispatch({
            type: SHORTCUT_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const updateExpandableShortcutAction = (id, title, link, icon, color, index) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/shortcut/expandable/${id}`, { title, link, icon, color, index }, config);

        dispatch({
            type: SHORTCUT_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const deleteShortcutAction = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/shortcut/${id}`, config);

        dispatch({
            type: SHORTCUT_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_DELETE_FAIL,
            payload: message,
        });
    }
}

export const deleteExpandableShortcutAction = (id, index) => async(dispatch, getState) => {
    try {
        dispatch({
            type: SHORTCUT_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/shortcut/expandable/delete/${id}`, {index}, config);

        dispatch({
            type: SHORTCUT_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: SHORTCUT_DELETE_FAIL,
            payload: message,
        });
    }
}