import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import Layout from '../components/Layout/Layout'
import Notepads from '../components/Dashboard/Notepads';
import Shortcuts from '../components/Dashboard/Shortcuts';
import ReminderWidget from '../components/Dashboard/ReminderWidget';
import EmptyReminderWidget from '../components/Dashboard/EmptyReminderWidget';
import EditButton from '../components/Layout/EditButton';
import { useSelector } from 'react-redux';

const Dashboard = () => {

  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          history('/login?next=/dashboard');
      }
  }, [userInfo, history])

    const categoryGet = useSelector(state => state.categoryGet);
    const { length: categoryLength, error: categoryError } = categoryGet;

      return (
        <>
          <Layout title="Dashboard">
              <div className='flex flex-col lg:flex-row flex-grow lg:content-center lg:justify-center rounded-lg'>
                  <div className='p-2 pt-4 lg:space-y-4 justify-center md:justify-start gap-2 lg:gap-0 flex flex-row flex-wrap lg:flex-col mx-auto lg:mx-0'>
                    <Shortcuts />
                  </div>
                  <div className='p-2 flex-grow flex flex-col lg:w-2/6'>
                    <Notepads />
                  </div>
                  <div className='p-2 lg:w-2/5'>
                      <ReminderWidget />
                  </div>
              </div>
          </Layout>
          <div className='block fixed right-5 bottom-5 z-50 text-white'>
            <EditButton/>
          </div>
        </>
      )
}

export default Dashboard