import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Layout/Loading';
import { login } from '../../redux-components/actions/userActions';
import swal from 'sweetalert2';
import { USER_LOGIN_FAIL_ACKNOWLEDGE } from '../../redux-components/constants/userConstants';
import parse from 'url-parse'
import {Link} from "react-router-dom";

const Login = () => {

    const location = useLocation();
    const parsed = parse(location.search, true);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const history = useNavigate();
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, userInfo } = userLogin;

    useEffect(() => {
        if(userInfo) {
            if(!parsed.query.next) {
                history('/');
            } else {
                history(parsed.query.next);
            }
        }
    }, [userInfo, history, parsed.query.next])

    if(error) {
        new swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "OK",
        }).then(() => {
            dispatch({type: USER_LOGIN_FAIL_ACKNOWLEDGE});
        })
    }

    const submitHandler = async(e) => {
        e.preventDefault();

        try {
            if(!username || !password) {
                throw new Error("Please fill all fields");
            }

            dispatch(login(username, password));
        } catch (e) {
            new swal({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            })
        }
    }

    return (
        <div className='items-center justify-center flex w-full md:w-3/6 text-center mx-auto'>
            <div className='flex flex-col backdrop-filter backdrop-blur-md bg-black rounded-lg bg-opacity-40 w-full'>
                {loading && <Loading absolute/>}
                <div className='p-4 rounded-lg'>
                    <h1 className='text-white p-2 mb-4 text-3xl'>Login</h1>
                    <form className='flex flex-col gap-4'>
                        <div className='flex flex-col gap-2'>
                            <label className='text-white text-start'>Email</label>
                            <input autoFocus value={username} onChange={(e) => setUsername(e.target.value)} type='text' className='rounded-md p-1.5'></input>
                        </div>
                        <div className='flex flex-col gap-2 text-start'>
                            <label className='text-white'>Password</label>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' className='rounded-md p-1.5'></input>
                        </div>
                        <div className='flex flex-row gap-2 justify-between'>
                            <button type='submit' onClick={submitHandler} className='bg-green-500 rounded-md p-2 w-full hover:bg-green-400 text-white hover:text-black'>Submit</button>
                        </div>
                        <div className='flex flex-row gap-2 justify-center'>
                            <p className={'text-white'}><Link to={`/register?next=${parsed.query.next}`} className={'text-white hover:underline'}>Create an Account</Link> | <Link to={'/forgotpassword'} className={'text-white hover:underline'}>Forgot Password?</Link></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login