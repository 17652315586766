import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_FAIL,
    USER_REGISTER_SUCCESS,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGOUT,
    USER_DELETE_FAIL,
    USER_DELETE_SUCCESS,
    USER_DELETE_REQUEST,
    USER_REGISTER_FAIL_ACKNOWLEDGE,
    USER_LOGIN_FAIL_ACKNOWLEDGE,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAIL,
    USER_RESET_PASSWORD_FAIL_ACKNOWLEDGE, USER_UPDATE_SUCCESS, USER_UPDATE_REQUEST, USER_UPDATE_USER_SUCCESS
} from '../constants/userConstants';

export const userRegisterReducer = (state={}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true };
        case USER_REGISTER_SUCCESS:
            return { loading: false, accountCreated: true };
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        case USER_REGISTER_FAIL_ACKNOWLEDGE:
            return {};
        case USER_LOGIN_REQUEST:
            return {};
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const userLoginReducer = (state={}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload };
        case USER_UPDATE_USER_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_UPDATE_SUCCESS:
            return { loading: false, userInfo: {
                ...state.userInfo, settings: action.payload
                }
            };
        case USER_LOGIN_FAIL_ACKNOWLEDGE:
            return {};
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const userResetReducer = (state={}, action) => {
    switch (action.type) {
        case USER_RESET_PASSWORD_REQUEST:
            return { loading: true };
        case USER_RESET_PASSWORD_SUCCESS:
            return { loading: false, message: action.payload };
        case USER_RESET_PASSWORD_FAIL:
            return { loading: false, error: action.payload };
        case USER_RESET_PASSWORD_FAIL_ACKNOWLEDGE:
            return {};
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const userDeleteReducer = (state={}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true };
        case USER_DELETE_SUCCESS:
            return { loading: false, message: action.payload };
        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        case USER_LOGIN_REQUEST:
            return {};
        default:
            return state;
    }
}