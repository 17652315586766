import {
    CHECKLIST_CATEGORY_CREATE_ACKNOWLEDGE,
    CHECKLIST_CATEGORY_CREATE_FAIL,
    CHECKLIST_CATEGORY_CREATE_REQUEST,
    CHECKLIST_CATEGORY_CREATE_SUCCESS,
    CHECKLIST_CATEGORY_DELETE_ACKNOWLEDGE,
    CHECKLIST_CATEGORY_DELETE_FAIL,
    CHECKLIST_CATEGORY_DELETE_REQUEST,
    CHECKLIST_CATEGORY_DELETE_SUCCESS,
    CHECKLIST_CATEGORY_GET_FAIL,
    CHECKLIST_CATEGORY_GET_REQUEST,
    CHECKLIST_CATEGORY_GET_SUCCESS,
    CHECKLIST_CATEGORY_UPDATE_ACKNOWLEDGE,
    CHECKLIST_CATEGORY_UPDATE_FAIL,
    CHECKLIST_CATEGORY_UPDATE_REQUEST,
    CHECKLIST_CATEGORY_UPDATE_SUCCESS,
    CHECKLIST_ITEM_CREATE_ACKNOWLEDGE,
    CHECKLIST_ITEM_CREATE_FAIL,
    CHECKLIST_ITEM_CREATE_REQUEST,
    CHECKLIST_ITEM_CREATE_SUCCESS,
    CHECKLIST_ITEM_DELETE_ACKNOWLEDGE,
    CHECKLIST_ITEM_DELETE_FAIL,
    CHECKLIST_ITEM_DELETE_REQUEST,
    CHECKLIST_ITEM_DELETE_SUCCESS,
    CHECKLIST_ITEM_GET_FAIL,
    CHECKLIST_ITEM_GET_REQUEST,
    CHECKLIST_ITEM_GET_SUCCESS,
    CHECKLIST_ITEM_UPDATE_ACKNOWLEDGE,
    CHECKLIST_ITEM_UPDATE_FAIL,
    CHECKLIST_ITEM_UPDATE_REQUEST,
    CHECKLIST_ITEM_UPDATE_SUCCESS
} from "../constants/checklistConstants";
import {USER_LOGOUT} from "../constants/userConstants";

export const checklistItemGetReducer = (state={}, action) => {
    switch(action.type) {
        case CHECKLIST_ITEM_GET_REQUEST:
            return { loading: true };
        case CHECKLIST_ITEM_GET_SUCCESS:
            return { loading: false, checklistItems: action.payload, length: action.length };
        case CHECKLIST_ITEM_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const checklistItemCreateReducer = (state={}, action) => {
    switch(action.type) {
        case CHECKLIST_ITEM_CREATE_REQUEST:
            return { loading: true };
        case CHECKLIST_ITEM_CREATE_SUCCESS:
            return { loading: false, success: true };
        case CHECKLIST_ITEM_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case CHECKLIST_ITEM_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const checklistItemUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case CHECKLIST_ITEM_UPDATE_REQUEST:
            return { loading: true };
        case CHECKLIST_ITEM_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CHECKLIST_ITEM_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case CHECKLIST_ITEM_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const checklistItemDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case CHECKLIST_ITEM_DELETE_REQUEST:
            return {  };
        case CHECKLIST_ITEM_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CHECKLIST_ITEM_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case CHECKLIST_ITEM_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const checklistCategoryGetReducer = (state={}, action) => {
    switch(action.type) {
        case CHECKLIST_CATEGORY_GET_REQUEST:
            return { loading: true };
        case CHECKLIST_CATEGORY_GET_SUCCESS:
            return { loading: false, checklistCategorys: action.payload, length: action.length, first: action.first };
        case CHECKLIST_CATEGORY_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const checklistCategoryCreateReducer = (state={}, action) => {
    switch(action.type) {
        case CHECKLIST_CATEGORY_CREATE_REQUEST:
            return { loading: true };
        case CHECKLIST_CATEGORY_CREATE_SUCCESS:
            return { loading: false, success: true };
        case CHECKLIST_CATEGORY_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case CHECKLIST_CATEGORY_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const checklistCategoryUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case CHECKLIST_CATEGORY_UPDATE_REQUEST:
            return { loading: true };
        case CHECKLIST_CATEGORY_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CHECKLIST_CATEGORY_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case CHECKLIST_CATEGORY_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const checklistCategoryDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case CHECKLIST_CATEGORY_DELETE_REQUEST:
            return {  };
        case CHECKLIST_CATEGORY_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CHECKLIST_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case CHECKLIST_CATEGORY_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}