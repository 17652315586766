import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import EditButton from '../../components/Layout/EditButton'
import Shortcuts from '../../components/Dashboard/Shortcuts'
import Layout from '../../components/Layout/Layout'
import { useNavigate } from 'react-router'

const Shorts = () => {

  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          history('/login?next=/shortcuts');
      }
  }, [userInfo, history])

  return (
    <>
      <Layout col={true} title={"My Shortcuts"}>
        <div className='p-2 pt-4 justify-center gap-4 flex flex-row flex-wrap mx-auto'>
          <Shortcuts solo />
        </div>
        <div className='flex flex-grow'></div>
      </Layout>
        <div className='block fixed right-5 bottom-5 z-50 text-white'>
            <EditButton/>
        </div>
    </>
  )
}
//TODO: shortcuts solo page enhancement: instead of circles maybe it can be cards with the icon inside it so it looks better than just some circles

export default Shorts