import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {customSwal} from "../../CustomSwal";
import {createChecklistCategory} from "../../../redux-components/actions/checklistActions";
import {useDispatch} from "react-redux";

const ChecklistCategoryCreate = () => {
    const dispatch = useDispatch();

    async function createHandler() {
        const { value: formValues } = await customSwal.fire({
            title: `Create a Category`,
            html:
                `<div class='flex flex-col'>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Category Title</label>
                        <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                    </div>
                    <div class='flex flex-col text-black'>
                        <label class='text-start text-xl font-bold'>Category Color</label>
                        <select id='newColor' class='p-2 rounded-md bg-zinc-300'>
                            <option class='bg-red-500' value='bg-red-500 hover:bg-red-700'>Red</option>
                            <option class='bg-green-500' value='bg-green-500 hover:bg-green-700'>Green</option>
                            <option class='bg-blue-500' value='bg-blue-500 hover:bg-blue-700'>Blue</option>
                            <option class='bg-purple-500' value='bg-purple-500 hover:bg-purple-700'>Purple</option>
                            <option class='bg-pink-500' value='bg-pink-500 hover:bg-pink-700'>Pink</option>
                            <option class='bg-yellow-500' value='bg-yellow-500 hover:bg-yellow-700'>Yellow</option>
                            <option class='bg-orange-500' value='bg-orange-500 hover:bg-orange-700'>Orange</option>
                        </select>
                    </div>
                </div>`,
            confirmButtonText: 'Save',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            preConfirm: () => {
                return [
                    document.getElementById('newTitle').value,
                    document.getElementById('newColor').value,
                ]
            }
        })

        if(formValues) {
            dispatch(createChecklistCategory(formValues[0], formValues[1]));
        }
    }

    return (
        <div onClick={createHandler} className={`h-14 rounded-md cursor-pointer flex flex-row text-center p-4 bg-zinc-500 hover:bg-zinc-700 items-center justify-between content-center`}>
            <p className={'text-2xl'}>Create Category</p>
            <div className={`flex items-center justify-center cursor-pointer p-2 rounded-full h-7 w-7`}>
                <FontAwesomeIcon icon={"plus"} />
            </div>
        </div>
    );
};

export default ChecklistCategoryCreate;