export const SHORTCUT_GET_REQUEST = "SHORTCUT_GET_REQUEST";
export const SHORTCUT_GET_SUCCESS = "SHORTCUT_GET_SUCCESS";
export const SHORTCUT_GET_FAIL = "SHORTCUT_GET_FAIL";

export const SHORTCUT_CREATE_REQUEST = "SHORTCUT_CREATE_REQUEST";
export const SHORTCUT_CREATE_SUCCESS = "SHORTCUT_CREATE_SUCCESS";
export const SHORTCUT_CREATE_FAIL = "SHORTCUT_CREATE_FAIL";
export const SHORTCUT_CREATE_ACKNOWLEDGE = "SHORTCUT_CREATE_ACKNOWLEDGE";

export const SHORTCUT_UPDATE_REQUEST = "SHORTCUT_UPDATE_REQUEST";
export const SHORTCUT_UPDATE_SUCCESS = "SHORTCUT_UPDATE_SUCCESS";
export const SHORTCUT_UPDATE_FAIL = "SHORTCUT_UPDATE_FAIL";
export const SHORTCUT_UPDATE_ACKNOWLEDGE = "SHORTCUT_UPDATE_ACKNOWLEDGE";

export const SHORTCUT_DELETE_REQUEST = "SHORTCUT_DELETE_REQUEST";
export const SHORTCUT_DELETE_SUCCESS = "SHORTCUT_DELETE_SUCCESS";
export const SHORTCUT_DELETE_FAIL = "SHORTCUT_DELETE_FAIL";
export const SHORTCUT_DELETE_ACKNOWLEDGE = "SHORTCUT_DELETE_ACKNOWLEDGE";