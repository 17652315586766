import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Notepads from '../../components/Dashboard/Notepads'
import Layout from '../../components/Layout/Layout'
import { useNavigate } from 'react-router'

const Notes = () => {

  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          history('/login?next=/notes');
      }
  }, [userInfo, history])

  return (
    <Layout title={"My Notes"}>
        <div className='flex flex-col w-full flex-grow'>
            <Notepads/>
        </div>
    </Layout>
  )
}

export default Notes