import { REMINDER_CATEGORY_CLEAR_ACKNOWLEDGE, REMINDER_CATEGORY_CLEAR_FAIL, REMINDER_CATEGORY_CLEAR_REQUEST, REMINDER_CATEGORY_CLEAR_SUCCESS, REMINDER_CATEGORY_CREATE_ACKNOWLEDGE, REMINDER_CATEGORY_CREATE_FAIL, REMINDER_CATEGORY_CREATE_REQUEST, REMINDER_CATEGORY_CREATE_SUCCESS, REMINDER_CATEGORY_DELETE_ACKNOWLEDGE, REMINDER_CATEGORY_DELETE_ALL_ACKNOWLEDGE, REMINDER_CATEGORY_DELETE_ALL_FAIL, REMINDER_CATEGORY_DELETE_ALL_REQUEST, REMINDER_CATEGORY_DELETE_ALL_SUCCESS, REMINDER_CATEGORY_DELETE_FAIL, REMINDER_CATEGORY_DELETE_REQUEST, REMINDER_CATEGORY_DELETE_SUCCESS, REMINDER_CATEGORY_GET_FAIL, REMINDER_CATEGORY_GET_REQUEST, REMINDER_CATEGORY_GET_SUCCESS, REMINDER_CATEGORY_IMPORT_ACKNOWLEDGE, REMINDER_CATEGORY_IMPORT_FAIL, REMINDER_CATEGORY_IMPORT_REQUEST, REMINDER_CATEGORY_IMPORT_SUCCESS, REMINDER_CATEGORY_UPDATE_ACKNOWLEDGE, REMINDER_CATEGORY_UPDATE_FAIL, REMINDER_CATEGORY_UPDATE_REQUEST, REMINDER_CATEGORY_UPDATE_SUCCESS, REMINDER_CREATE_ACKNOWLEDGE, REMINDER_CREATE_FAIL, REMINDER_CREATE_REQUEST, REMINDER_CREATE_SUCCESS, REMINDER_DELETE_ACKNOWLEDGE, REMINDER_DELETE_FAIL, REMINDER_DELETE_REQUEST, REMINDER_DELETE_SUCCESS, REMINDER_GET_FAIL, REMINDER_GET_REQUEST, REMINDER_GET_SUCCESS, REMINDER_UPDATE_ACKNOWLEDGE, REMINDER_UPDATE_FAIL, REMINDER_UPDATE_REQUEST, REMINDER_UPDATE_SUCCESS } from "../constants/reminderConstants";
import { USER_LOGOUT } from "../constants/userConstants";

export const categoryGetReducer = (state={}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_GET_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_GET_SUCCESS:
            return { loading: false, categories: action.payload, length: action.length, first: action.first };
        case REMINDER_CATEGORY_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const reminderGetReducer = (state={}, action) => {
    switch(action.type) {
        case REMINDER_GET_REQUEST:
            return { loading: true };
        case REMINDER_GET_SUCCESS:
            return { loading: false, reminders: action.payload };
        case REMINDER_GET_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const reminderCreateReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CREATE_REQUEST:
            return { loading: true };
        case REMINDER_CREATE_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const categoryCreateReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_CREATE_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_CREATE_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CATEGORY_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CATEGORY_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const categoryUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_UPDATE_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CATEGORY_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CATEGORY_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const reminderUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_UPDATE_REQUEST:
            return { loading: true };
        case REMINDER_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const reminderDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_DELETE_REQUEST:
            return {  };
        case REMINDER_DELETE_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const categoryDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_DELETE_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_DELETE_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CATEGORY_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const categoryDeleteAllReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_DELETE_ALL_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_DELETE_ALL_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CATEGORY_DELETE_ALL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CATEGORY_DELETE_ALL_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const categoryClearReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_CLEAR_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_CLEAR_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CATEGORY_CLEAR_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CATEGORY_CLEAR_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const adminImportReminderReducer = (state = {}, action) => {
    switch(action.type) {
        case REMINDER_CATEGORY_IMPORT_REQUEST:
            return { loading: true };
        case REMINDER_CATEGORY_IMPORT_SUCCESS:
            return { loading: false, success: true };
        case REMINDER_CATEGORY_IMPORT_FAIL:
            return { loading: false, error: action.payload, success: false };
        case REMINDER_CATEGORY_IMPORT_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}