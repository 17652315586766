import axios from "axios";
import { NOTEPAD_CREATE_FAIL, NOTEPAD_CREATE_REQUEST, NOTEPAD_CREATE_SUCCESS, NOTEPAD_DELETE_FAIL, NOTEPAD_DELETE_REQUEST, NOTEPAD_DELETE_SUCCESS, NOTEPAD_GET_FAIL, NOTEPAD_GET_REQUEST, NOTEPAD_GET_SUCCESS, NOTEPAD_UPDATE_FAIL, NOTEPAD_UPDATE_REQUEST, NOTEPAD_UPDATE_SUCCESS } from '../constants/notepadConstants';

export const loadNotepads = () => async(dispatch, getState) => {
    try {
        dispatch({type: NOTEPAD_GET_REQUEST});

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/notepad/`, config);

        dispatch({type: NOTEPAD_GET_SUCCESS, payload: data, length: data.length});

    } catch (error) {
        dispatch({
            type: NOTEPAD_GET_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const updateNoteAction = (id, content, title) => async(dispatch, getState) => {
    try {
        dispatch({
            type: NOTEPAD_UPDATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.put(`/api/notepad/${id}`, { content, title }, config);

        dispatch({
            type: NOTEPAD_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: NOTEPAD_UPDATE_FAIL,
            payload: message,
        });
    }
}

export const createNoteAction = (title) => async(dispatch, getState) => {
    try {
        dispatch({
            type: NOTEPAD_CREATE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const content = "Click on the Edit button on the right to start writing notes!";

        const { data } = await axios.post(`/api/notepad/create`, { title, content }, config);

        dispatch({
            type: NOTEPAD_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: NOTEPAD_CREATE_FAIL,
            payload: message,
        });
    }

}

export const deleteNoteAction = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: NOTEPAD_DELETE_REQUEST,
        });

        const { userLogin: { userInfo }} = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            },
        };

        const { data } = await axios.delete(`/api/notepad/${id}`, config);

        dispatch({
            type: NOTEPAD_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message;
        dispatch({
            type: NOTEPAD_DELETE_FAIL,
            payload: message,
        });
    }
}