import React from 'react'

const ErrorMessage = ({children}) => {
  return (
    <div className='bg-red-500 w-full p-2 rounded-md text-white font-bold'>
        {children}
    </div>
  )
}

export default ErrorMessage