import { SHORTCUT_CREATE_ACKNOWLEDGE, SHORTCUT_CREATE_FAIL, SHORTCUT_CREATE_REQUEST, SHORTCUT_CREATE_SUCCESS, SHORTCUT_DELETE_ACKNOWLEDGE, SHORTCUT_DELETE_FAIL, SHORTCUT_DELETE_REQUEST, SHORTCUT_DELETE_SUCCESS, SHORTCUT_GET_FAIL, SHORTCUT_GET_REQUEST, SHORTCUT_GET_SUCCESS, SHORTCUT_UPDATE_ACKNOWLEDGE, SHORTCUT_UPDATE_FAIL, SHORTCUT_UPDATE_REQUEST, SHORTCUT_UPDATE_SUCCESS } from "../constants/shortcutConstants";

export const shortcutGetReducer = (state={}, action) => {
    switch(action.type) {
        case SHORTCUT_GET_REQUEST:
            return { loading: true };
        case SHORTCUT_GET_SUCCESS:
            return { loading: false, shortcuts: action.payload, length: action.length };
        case SHORTCUT_GET_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const shortcutCreateReducer = (state={}, action) => {
    switch(action.type) {
        case SHORTCUT_CREATE_REQUEST:
            return { loading: true };
        case SHORTCUT_CREATE_SUCCESS:
            return { loading: false, success: true, error: "" };
        case SHORTCUT_CREATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case SHORTCUT_CREATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const shortcutUpdateReducer = (state={}, action) => {
    switch(action.type) {
        case SHORTCUT_UPDATE_REQUEST:
            return { loading: true };
        case SHORTCUT_UPDATE_SUCCESS:
            return { loading: false, success: true, error: "" };
        case SHORTCUT_UPDATE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case SHORTCUT_UPDATE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}

export const shortcutDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case SHORTCUT_DELETE_REQUEST:
            return { loading: true };
        case SHORTCUT_DELETE_SUCCESS:
            return { loading: false, success: true, error: "" };
        case SHORTCUT_DELETE_FAIL:
            return { loading: false, error: action.payload, success: false };
        case SHORTCUT_DELETE_ACKNOWLEDGE:
            return { };
        default:
            return state;
    }
}