/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    createGroceryFavorite, deleteGroceryFavorite,
    deleteGroceryItem,
    updateGroceryItem
} from "../../redux-components/actions/groceryActions";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities'
import {Toast} from "../CustomSwal";

const GroceryItem = props => {
    const dispatch = useDispatch();

    const editMode = useSelector((state) => state.editMode);
    const groceryItemUpdate = useSelector((state) => state.groceryItemUpdate);
    const { success: successUpdate } = groceryItemUpdate;

    const [edit, setEdit] = useState(false);
    const [description, setDescription] = useState(props.description || "");

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    useEffect(() => {
        let debounce = setTimeout(() => {
            updateHandler();
        }, 1000);
        return () => {
            clearTimeout(debounce);
        }
    }, [description]);

    const deleteHandler = () => {
        dispatch(deleteGroceryItem(props._id))
        //console.log(`deleting id: ${props._id}`)
    }

    const updateHandler = () => {
        if(props.description !== description) {
            try {
                dispatch(updateGroceryItem(props._id, description, props.position));

                if(!successUpdate) {
                    throw new Error('Error Updating Grocery Item');
                }

                Toast.fire({
                    icon: 'success',
                    title: 'Grocery Item updated'
                })
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                })
            }
        }
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: props._id})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={`bg-neutral-800 flex space-x-2 touch-manipulation flex-row p-2 text-black rounded-md w-full content-center justify-center items-center`}>
                {edit && <div onClick={deleteHandler}
                      className={'flex items-center h-8 w-8 justify-center text-red-500 cursor-pointer hover:text-red-300'}>
                    <FontAwesomeIcon icon={'fas fa-trash'} size={'xl'}/>
                </div>}
                <input onChange={(e) => setDescription(e.target.value)} type={'text'} value={description} className={'flex flex-grow bg-transparent text-center placeholder-black content-center items-center justify-center w-full p-2 overflow-auto max-h-36 empty-scroll text-2xl text-white'} />
            </div>
        </>
    );
};

export default GroceryItem;
