import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert2'
import { createNoteAction, loadNotepads } from '../../redux-components/actions/notepadActions';

const EmptyNotepads = () => {

  const dispatch = useDispatch();

  const createHandler = async() => {
      const { value: title } = await swal.fire({
        title: 'Notepad Title',
        input: 'text',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Please fill all fields'
          }
        }
      })

      if (title) {
        dispatch(createNoteAction(title))
        dispatch(loadNotepads());
      }
  }

  return (
    <div className='flex flex-row space-x-4 flex-grow relative text-black bg-white content-center items-center justify-center'>
        <div>
            <FontAwesomeIcon onClick={createHandler} icon='fas fa-plus-circle' size='3x' className='hover:text-gray-700 cursor-pointer' />
        </div>
        <div>
            <h1 className='text-2xl'>You don't have any Notepads!</h1>
            <p className='text-lg'><FontAwesomeIcon icon='fas fa-arrow-left' /> Create One Now!</p>
        </div>
    </div>
  )
}

export default EmptyNotepads