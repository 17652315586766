export const CHECKLIST_ITEM_GET_REQUEST = "CHECKLIST_ITEM_GET_REQUEST";
export const CHECKLIST_ITEM_GET_SUCCESS = "CHECKLIST_ITEM_GET_SUCCESS";
export const CHECKLIST_ITEM_GET_FAIL = "CHECKLIST_ITEM_GET_FAIL";
export const CHECKLIST_ITEM_CREATE_REQUEST = "CHECKLIST_ITEM_CREATE_REQUEST";
export const CHECKLIST_ITEM_CREATE_SUCCESS = "CHECKLIST_ITEM_CREATE_SUCCESS";
export const CHECKLIST_ITEM_CREATE_FAIL = "CHECKLIST_ITEM_CREATE_FAIL";
export const CHECKLIST_ITEM_CREATE_ACKNOWLEDGE = "CHECKLIST_ITEM_CREATE_ACKNOWLEDGE";
export const CHECKLIST_ITEM_UPDATE_REQUEST = "CHECKLIST_ITEM_UPDATE_REQUEST";
export const CHECKLIST_ITEM_UPDATE_SUCCESS = "CHECKLIST_ITEM_UPDATE_SUCCESS";
export const CHECKLIST_ITEM_UPDATE_FAIL = "CHECKLIST_ITEM_UPDATE_FAIL";
export const CHECKLIST_ITEM_UPDATE_ACKNOWLEDGE = "CHECKLIST_ITEM_UPDATE_ACKNOWLEDGE";
export const CHECKLIST_ITEM_DELETE_REQUEST = "CHECKLIST_ITEM_DELETE_REQUEST";
export const CHECKLIST_ITEM_DELETE_SUCCESS = "CHECKLIST_ITEM_DELETE_SUCCESS";
export const CHECKLIST_ITEM_DELETE_FAIL = "CHECKLIST_ITEM_DELETE_FAIL";
export const CHECKLIST_ITEM_DELETE_ACKNOWLEDGE = "CHECKLIST_ITEM_DELETE_ACKNOWLEDGE";
export const CHECKLIST_CATEGORY_GET_REQUEST = "CHECKLIST_CATEGORY_GET_REQUEST";
export const CHECKLIST_CATEGORY_GET_SUCCESS = "CHECKLIST_CATEGORY_GET_SUCCESS";
export const CHECKLIST_CATEGORY_GET_FAIL = "CHECKLIST_CATEGORY_GET_FAIL";
export const CHECKLIST_CATEGORY_CREATE_REQUEST = "CHECKLIST_CATEGORY_CREATE_REQUEST";
export const CHECKLIST_CATEGORY_CREATE_SUCCESS = "CHECKLIST_CATEGORY_CREATE_SUCCESS";
export const CHECKLIST_CATEGORY_CREATE_FAIL = "CHECKLIST_CATEGORY_CREATE_FAIL";
export const CHECKLIST_CATEGORY_CREATE_ACKNOWLEDGE = "CHECKLIST_CATEGORY_CREATE_ACKNOWLEDGE";
export const CHECKLIST_CATEGORY_UPDATE_REQUEST = "CHECKLIST_CATEGORY_UPDATE_REQUEST";
export const CHECKLIST_CATEGORY_UPDATE_SUCCESS = "CHECKLIST_CATEGORY_UPDATE_SUCCESS";
export const CHECKLIST_CATEGORY_UPDATE_FAIL = "CHECKLIST_CATEGORY_UPDATE_FAIL";
export const CHECKLIST_CATEGORY_UPDATE_ACKNOWLEDGE = "CHECKLIST_CATEGORY_UPDATE_ACKNOWLEDGE";
export const CHECKLIST_CATEGORY_DELETE_REQUEST = "CHECKLIST_CATEGORY_DELETE_REQUEST";
export const CHECKLIST_CATEGORY_DELETE_SUCCESS = "CHECKLIST_CATEGORY_DELETE_SUCCESS";
export const CHECKLIST_CATEGORY_DELETE_FAIL = "CHECKLIST_CATEGORY_DELETE_FAIL";
export const CHECKLIST_CATEGORY_DELETE_ACKNOWLEDGE = "CHECKLIST_CATEGORY_DELETE_ACKNOWLEDGE";
export const CHECKLIST_CATEGORY_UNCHECK_ALL = "CHECKLIST_CATEGORY_UNCHECK_ALL"