/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    createGroceryItem,
    deleteGroceryFavorite
} from "../../redux-components/actions/groceryActions";
import Swal from "sweetalert2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toast} from "../CustomSwal";

const GroceryFavoritesList = () => {
    const dispatch = useDispatch();

    const groceryFavoritesGet = useSelector(state => state.groceryFavoritesGet);
    const { loading: groceryFavoritesGetLoading, error: groceryFavoritesGetError, groceryFavorites, length: favoritesLength } = groceryFavoritesGet;
    const editMode = useSelector((state) => state.editMode);

    const [favorites, setFavorites] = useState()
    const [openFavorites, setOpenFavorites] = useState(false)
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setEdit(editMode);
    }, [editMode])

    useEffect(() => {
        if(!groceryFavoritesGetLoading) {
            setFavorites(groceryFavorites)
        }
    }, [groceryFavorites, groceryFavoritesGetLoading]);

    const addHandler = (item_name) => {
        try {
            dispatch(createGroceryItem(item_name));
            setOpenFavorites(false);
        } catch (e) {
            Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            });
        }
    }

    const removeHandler = async(item_desc) => {
        try {
            await dispatch(deleteGroceryFavorite(item_desc));
            await Toast.fire({
                icon: 'success',
                title: 'Item removed from favorites'
            })
        } catch (e) {
            await Toast.fire({
                icon: 'error',
                title: e.message
            })
        }
    }

    return (
        <div className={'flex relative flex-col w-full rounded-md z-30'}>
            <h1 onClick={() => setOpenFavorites(!openFavorites)} className={`border-black border-2 text-xl cursor-pointer text-black w-full rounded-md ${openFavorites ? "rounded-b-none bg-zinc-500 hover:bg-white text-white hover:text-black" : "rounded-b-md bg-white hover:bg-zinc-500 hover:text-white"}`}>Favorites</h1>
            <div className={`${openFavorites ? "flex absolute mt-7" : "hidden"} border-black border-2 border-t-0 p-2 flex-col w-full bg-zinc-500 rounded-md h-96 overflow-auto empty-scroll rounded-t-none`}>
                {favorites?.favorites?.map((item) =>
                    <div key={item} className={'flex flex-row content-center items-center justify-between p-1 space-x-2'}>
                        {edit && <div onClick={() => removeHandler(item)}
                              className={'flex items-center h-8 w-8 justify-center bg-red-500 hover:bg-red-600 hover:bg-white text-white cursor-pointer p-2 rounded-full'}>
                            <FontAwesomeIcon icon={'fas fa-x'} size={'xl'}/>
                        </div>}
                        <div key={item} onClick={()=>addHandler(item)} className={'border-black border-2 hover:bg-zinc-400 cursor-pointer flex space-x-2 touch-manipulation flex-row p-4 bg-white text-black rounded-md w-full content-center justify-between items-center'}>
                            <p className={'text-center flex-grow'}>{item}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GroceryFavoritesList;