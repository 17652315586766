import React, {useEffect, useState} from 'react';
import MicroLayout from "../../components/Layout/MicroLayout";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import swal from "sweetalert2";
import {logout, resetPassword} from "../../redux-components/actions/userActions";
import Swal from "sweetalert2";
import {USER_RESET_PASSWORD_FAIL_ACKNOWLEDGE} from "../../redux-components/constants/userConstants";

const ResetPassword = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if(!userInfo) {
            history('/login?next=/dashboard');
        }
    }, [userInfo, history])

    const userReset = useSelector(state => state.userReset);
    // eslint-disable-next-line
    const { success: resetSuccess, error: resetError } = userReset;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const submitHandler = async(e) => {
        e.preventDefault();
        const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#:.;?!@$ %^&*-]).{8,}$/;

        try {
            if(!password || !confirmPassword) {
                throw new Error('Please fill all the fields');
            }

            if(password !== confirmPassword) {
                throw new Error('Passwords do not match');
            }

            if(password.length < 8) {
                throw new Error('Password must be 8 characters long');
            }

            if(!passwordPattern.test(password)) {
                throw new Error("Password must be atleast 8 characters long with 1 capital, 1 number, and 1 symbol");
            }

            //dispatch password update
            dispatch(resetPassword(password, confirmPassword))
            Swal.fire({
                title: "Success",
                text: "Password updated successfully. Please re-login",
                icon: "success",
                button: "OK",
            }).then(dispatch(logout()));
        } catch (e) {
            new swal({
                title: "Error",
                text: e.message,
                icon: "error",
                button: "OK",
            })
        }
    }

    if(resetError) {
        Swal.fire({
            title: "Error",
            text: resetError,
            icon: "error",
            button: "OK",
        }).then(dispatch({type: USER_RESET_PASSWORD_FAIL_ACKNOWLEDGE}));
    }

    return (
        <MicroLayout title={'Password Reset'} headTitle={'Password Reset'}>
            <div className={'flex flex-col content-center items-center justify-center text-white text-start p-4'}>
                <form className={'flex flex-col space-y-4 w-full'}>
                    <div className={'flex flex-col space-y-2'}>
                        <label className='text-white'>New Password</label>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' className='rounded-md p-1.5 text-black'></input>
                    </div>
                    <div className={'flex flex-col space-y-2'}>
                        <label className='text-white'>Confirm New Password</label>
                        <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' className='rounded-md p-1.5 text-black'></input>
                    </div>
                    <div className='flex flex-row gap-2 justify-between'>
                        <button type='submit' onClick={submitHandler} className='bg-green-500 rounded-md p-2 w-full hover:bg-green-400 text-white hover:text-black'>Reset Password</button>
                    </div>
                </form>
            </div>
        </MicroLayout>
    );
};

export default ResetPassword;
