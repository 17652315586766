import { combineReducers } from 'redux'
import thunk from 'redux-thunk'
import {
    userDeleteReducer,
    userLoginReducer,
    userRegisterReducer,
    userResetReducer
} from './redux-components/reducers/userReducers'
import { notepadCreateReducer, notepadDeleteReducer, notepadGetReducer, notepadUpdateReducer } from './redux-components/reducers/notepadReducers'
import { shortcutCreateReducer, shortcutDeleteReducer, shortcutGetReducer, shortcutUpdateReducer } from './redux-components/reducers/shortcutReducers'
import { adminImportReminderReducer, categoryClearReducer, categoryCreateReducer, categoryDeleteAllReducer, categoryDeleteReducer, categoryGetReducer, categoryUpdateReducer, reminderCreateReducer, reminderDeleteReducer, reminderGetReducer, reminderUpdateReducer } from './redux-components/reducers/reminderReducers'
import { editModeReducer } from './redux-components/reducers/editModeReducers'
import {
    groceryFavoritesCreateReducer, groceryFavoritesDeleteAllReducer, groceryFavoritesDeleteReducer,
    groceryFavoritesGetReducer, groceryFavoritesUpdateReducer,
    groceryItemCreateReducer, groceryItemDeleteAllReducer, groceryItemDeleteReducer,
    groceryItemGetReducer,
    groceryItemUpdateReducer, groceryUndoListReducer
} from "./redux-components/reducers/groceryReducers";
import {
    checklistCategoryCreateReducer,
    checklistCategoryDeleteReducer,
    checklistCategoryGetReducer,
    checklistCategoryUpdateReducer,
    checklistItemCreateReducer, checklistItemDeleteReducer,
    checklistItemGetReducer,
    checklistItemUpdateReducer
} from "./redux-components/reducers/checklistReducers";
import {configureStore} from "@reduxjs/toolkit";

const reducer = combineReducers({
    editMode: editModeReducer,
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    userReset: userResetReducer,
    userDelete: userDeleteReducer,

    notepadGet: notepadGetReducer,
    notepadUpdate: notepadUpdateReducer,
    notepadCreate: notepadCreateReducer,
    notepadDelete: notepadDeleteReducer,

    shortcutGet: shortcutGetReducer,
    shortcutCreate: shortcutCreateReducer,
    shortcutUpdate: shortcutUpdateReducer,
    shortcutDelete: shortcutDeleteReducer,

    categoryGet: categoryGetReducer,
    reminderGet: reminderGetReducer,
    reminderCreate: reminderCreateReducer,
    categoryCreate: categoryCreateReducer,
    categoryUpdate: categoryUpdateReducer,
    reminderUpdate: reminderUpdateReducer,
    reminderDelete: reminderDeleteReducer,
    categoryDelete: categoryDeleteReducer,
    categoryDeleteAll: categoryDeleteAllReducer,
    adminImportReminder: adminImportReminderReducer,
    reminderClear: categoryClearReducer,

    groceryItemGet: groceryItemGetReducer,
    groceryItemCreate: groceryItemCreateReducer,
    groceryItemUpdate: groceryItemUpdateReducer,
    groceryItemDelete: groceryItemDeleteReducer,
    groceryItemDeleteAll: groceryItemDeleteAllReducer,
    groceryFavoritesGet: groceryFavoritesGetReducer,
    groceryFavoritesCreate: groceryFavoritesCreateReducer,
    groceryFavoritesDelete: groceryFavoritesDeleteReducer,
    groceryFavoritesDeleteAll: groceryFavoritesDeleteAllReducer,
    groceryFavoritesUpdate: groceryFavoritesUpdateReducer,
    groceryUndoList: groceryUndoListReducer,

    checklistCategoryCreate: checklistCategoryCreateReducer,
    checklistCategoryGet: checklistCategoryGetReducer,
    checklistCategoryUpdate: checklistCategoryUpdateReducer,
    checklistCategoryDelete: checklistCategoryDeleteReducer,
    checklistItemCreate: checklistItemCreateReducer,
    checklistItemGet: checklistItemGetReducer,
    checklistItemUpdate: checklistItemUpdateReducer,
    checklistItemDelete: checklistItemDeleteReducer,

});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: {userInfo:userInfoFromStorage},
    editMode: false,
    groceryUndoList: {
        undoList: []
    }
}

const middleware = [thunk];

export default configureStore({
    reducer: reducer,
    preloadedState: initialState,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        ...middleware,
    }),
    devTools: process.env.NODE_ENV === "development"
})
