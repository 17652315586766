import React from 'react';
import {Helmet} from "react-helmet";

const MicroLayout = (props) => {
    return (
        <div className='items-center justify-center flex flex-col w-3/6 text-center mx-auto'>
            <Helmet>
                <title>{props.headTitle || "PetsterGaming App"}</title>
            </Helmet>
            <div className='flex flex-col backdrop-filter backdrop-blur-md bg-black rounded-lg bg-opacity-40 w-full'>
                <div className='rounded-lg'>
                    <h1 className='text-white p-2 text-3xl'>{props.title || ""}</h1>
                </div>
                {props.children}
            </div>
        </div>
    );
};

export default MicroLayout;
