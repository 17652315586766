import React from 'react'
import Layout from '../../components/Layout/Layout'

const Admin = () => {
  return (
    <Layout title={"Admin"}>

    </Layout>
  )
}

export default Admin