import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert2';
import { createNoteAction, deleteNoteAction, loadNotepads, updateNoteAction } from '../../redux-components/actions/notepadActions';
import { NOTEPAD_CREATE_ACKNOWLEDGE, NOTEPAD_DELETE_ACKNOWLEDGE, NOTEPAD_UPDATE_ACKNOWLEDGE } from '../../redux-components/constants/notepadConstants';
import ErrorMessage from '../ErrorMessage';
import Loading from '../Layout/Loading';
import EmptyNotepads from './EmptyNotepads';
import { customSwal } from '../CustomSwal'

const Notepads = () => {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const notepadGet = useSelector(state => state.notepadGet);
    const { loading, error, notepads, length } = notepadGet;
    const notepadUpdate = useSelector((state) => state.notepadUpdate);
    const { success: successUpdate, error: errorUpdate } = notepadUpdate;
    const notepadCreate = useSelector((state) => state.notepadCreate);
    const { success: successCreate, error: errorCreate } = notepadCreate;
    const notepadDelete = useSelector((state) => state.notepadDelete);
    const { success: successDelete, error: errorDelete } = notepadDelete;

    const [edit, setEdit] = useState(true);
    const [newData, setNewData] = useState("");
    const [ID, setID] = useState("");
    const [newTitle, setNewTitle] = useState("");
    const [currentNote, setCurrentNote] = useState(0)

    useEffect(() => {
      async function notepadChange() {
        await dispatch(loadNotepads());
        if(successDelete) { setCurrentNote(0); }
        setEdit(false);
      }
      notepadChange();
    }, [dispatch, userInfo, successUpdate, successCreate, successDelete])

    const editHandler = (_id, title, content) => {
      setID(_id);
      setNewTitle(title);
      setNewData(content);
      if(edit) {
        dispatch(updateNoteAction(ID, newData, newTitle));
        setEdit(false);
      } else {
        setEdit(true);
      }
    }

    const changeNote = (_id, title, content) => {
      setID(_id);
      setNewTitle(title);
      setNewData(content);
    }

    const addNoteHandler = async() => {
      const { value: formValues } = await customSwal.fire({
        title: `Create a new Note`,
        html:
            `<div class='flex flex-col'>
                <div class='flex flex-col text-black'>
                    <label class='text-start text-xl font-bold'>Note Title</label>
                    <input id='newTitle' class='p-2 rounded-md bg-zinc-300' type='text' />
                </div>
            </div>`,
        confirmButtonText: 'Save',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        preConfirm: () => {
            return [
                document.getElementById('newTitle').value,
            ]
        }
    })

    if(formValues) {
      await dispatch(createNoteAction(formValues[0]));
      setCurrentNote(length);
    }
  }

    const deleteHandler = () => {
      new swal({
        title: "Are you Sure?",
        text: `You are about to delete a note with title "${newTitle}"`,
        icon: "warning",
        showCancelButton: true,
      }).then((willDelete) => {
        if(willDelete.isConfirmed) {
          dispatch(deleteNoteAction(ID));
          new swal({
            title: 'Notepad Deleted',
            icon: 'success'
          }).then((success) => {
            if(success) {
              dispatch({type: NOTEPAD_DELETE_ACKNOWLEDGE})
            }
          });
        }
      })
    }

    if(errorCreate) {
      new swal({
        title: "Error",
        text: errorCreate,
        icon: "error",
        button: "OK",
      }).then(dispatch({type: NOTEPAD_CREATE_ACKNOWLEDGE}));
    }

    if(errorDelete) {
      new swal({
        title: "Error",
        text: errorDelete,
        icon: "error",
        button: "OK",
      }).then(dispatch({type: NOTEPAD_DELETE_ACKNOWLEDGE}));
    }

    if(errorUpdate) {
      new swal({
        title: "Error",
        text: "Notepad cannot be empty!",
        icon: "error",
        button: "OK",
      }).then(dispatch({type: NOTEPAD_UPDATE_ACKNOWLEDGE}));
    }

    const tabClasses = `w-full cursor-pointer tab inline-block p-2 text-black rounded-t-lg active hover:bg-white truncate`;
    const markdownClasses = `prose-headings:m-0 prose-p:m-0 prose-p:break-all prose-headings:break-all prose-p:whitespace-pre-wrap prose-hr:m-0 prose-ul:m-0 prose-li:m-0 prose-li:break-all prose-ol:m-0 prose-hr:border-sky-600 prose-headings:text-sky-600 prose-li:whitespace-normal`;

    return (
        length <= 0 ? (
            <EmptyNotepads />
        ) : (
        <>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ul className={`tab-list list-none flex flex-wrap justify-start md:flex-nowrap text-sm font-medium text-center text-white sm:overflow-x-auto overflow-x-scroll tab-scroll`}>
                {loading && (
                  <>
                    <li className='mr-2'>
                      <div className={`tab inline-block text-black rounded-lg active bg-white truncate`}>
                        <div className='flex flex-grow bg-black bg-opacity-70 p-2'><Loading size={25} bg /></div>
                      </div>
                    </li>
                  </>
                )}
                {notepads?.map((da, index) =>
                    <li onClick={() => {setCurrentNote(index); changeNote(da._id, da.title, da.content)}} key={da._id} className='mr-2 flex-grow'>
                    {
                        edit
                        ? (
                        currentNote === index
                        ? (<input className={`${tabClasses} ${currentNote === index ? " bg-white font-bold" : "bg-gray-100"}`}  defaultValue={da.title} type='text' onChange={(e) => setNewTitle(e.target.value)}/>)
                        : (<div className={`${tabClasses} ${currentNote === index ? " bg-white font-bold" : "bg-gray-100"}`}>{da.title}</div>)
                        )
                        : (<div className={`${tabClasses} ${currentNote === index ? " bg-white font-bold" : "bg-gray-100"}`}>{da.title}</div>)
                    }
                    </li>
                )}
                {length < 5 ? (
                  <li className='mr-2'>
                    <div onClick={addNoteHandler} className='cursor-pointer tab inline-block p-2 text-white hover:text-gray-100 rounded-t-lg active'><FontAwesomeIcon icon="fas fa-plus-circle" size='lg' /></div>
                  </li>
                ) : ""}
            </ul>
            <div  className='flex flex-col relative text-black'>
                {loading && (
                  <div style={{minHeight: "650px", maxHeight: "650px"}} className={`${markdownClasses} tab-scroll leading-none bg-white text-black whitespace-pre-wrap font-sans flex-grow overflow-auto text-left`}>
                    <Loading absolute />
                  </div>
                )}
                {notepads?.map((da, index) =>
                <div key={da._id} className={`relative ${currentNote === index ? "" : "hidden"}`}>
                {
                    edit
                    ? (
                    <div className='h-full w-full mx-auto flex flex-col flex-grow'>
                        <textarea defaultValue={da.content} onChange={(e) => setNewData(e.target.value)} style={{minHeight: "650px", maxHeight: "650px"}} className='p-4 w-full h-96 md:h-full resize-none tab-scroll' />
                        <div className='absolute right-5 space-y-4 top-5 flex flex-col'>
                          <div onClick={() => setEdit(false)} className={`opacity-25 hover:opacity-100 bg-blue-500 hover:bg-blue-700 cursor-pointer h-14 w-14 editor text-white font-bold rounded-full`}>
                              <FontAwesomeIcon icon="fas fa-x" className='p-3 pl-3.5' size='2x' />
                          </div>
                          <div onClick={() => {editHandler(da._id, da.title, da.content)}} className={`opacity-25 hover:opacity-100 ${edit ? "bg-green-500 hover:bg-green-700" : "bg-blue-500 hover:bg-blue-700"} cursor-pointer h-14 w-14 editor text-white font-bold rounded-full`}>
                              {edit ? (<FontAwesomeIcon icon="fas fa-save" className='p-3 pl-3.5' size='2x' />) : (<FontAwesomeIcon icon="fas fa-pen" className='p-3' size='2x' />)}
                          </div>
                          <div onClick={deleteHandler} className={`opacity-25 hover:opacity-100 bg-red-500 hover:bg-red-700 cursor-pointer h-14 w-14 editor text-white font-bold rounded-full`}>
                              <FontAwesomeIcon icon="fas fa-trash" className='p-3 pl-3' size='2x' />
                          </div>
                        </div>
                    </div>
                    )
                    : (
                      <article style={{minHeight: "650px", maxHeight: "650px"}} className={`${markdownClasses} tab-scroll leading-none bg-white text-black font-sans flex-grow overflow-auto text-left`}>
                          <ReactMarkdown linkTarget={"_blank"} children={da.content} remarkPlugins={[remarkGfm]} className='marker:text-sky-600 prose prose-sky prose-sm p-6'></ReactMarkdown>
                          <div onClick={() => {dispatch(loadNotepads()); editHandler(da._id, da.title, da.content)}} className={`${edit ? "bg-green-500 hover:bg-green-700" : "bg-blue-500 hover:bg-blue-700"} cursor-pointer h-14 w-14 editor text-white font-bold rounded-full absolute right-5 top-5`}>
                          {edit ? (<FontAwesomeIcon icon="fas fa-save" className='p-3 pl-3.5' size='2x' />) : (<FontAwesomeIcon icon="fas fa-pen" className='p-3' size='2x' />)}
                          </div>
                      </article>
                    )
                }
                </div>
                )}
            </div>
        </>
        )
    )
    }

export default Notepads