import React, {useEffect, useRef, useState} from 'react'
import Logo from '../../img/logoBright.png'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux-components/actions/userActions';
import {useLocation} from "react-router";
import parse from "url-parse";

const navLinks = [
    {
        display: "Dashboard",
        url: "/dashboard",
        logged: true,
        id: 1
    },
    // {
    //     display: "Updates",
    //     url: "/updates",
    //     logged: false,
    //     id: 2
    // },
];

const userMenu = [
    {
        display: "Grocery",
        url: "/grocery"
    },
    {
        display: "Notes",
        url: "/notes"
    },
    {
        display: "Shortcuts",
        url: "/shortcuts",
    },
    {
        display: "Reminders",
        url: "/reminders",
    },
    {
        display: "Help",
        url: "/help",
    },
    {
        display: "Account Settings",
        url: "/settings",
    },
];

const adminMenu = [
    // {
    //     display: "Admin",
    //     url: "/admin"
    // },
    {
        display: "Checklist",
        url: "/checklist"
    }
];

const Header = () => {

    const location = useLocation();
    const parsed = parse(location.search, true);

    const [open, setOpen] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const history = useNavigate();

    const closeDropdown = () => {
        setOpen(false);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, []);

    const logoutHandler = () => {
        dispatch(logout());
        history('/');
        setOpen(false);
    }

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    return (
        <div className='relative bg-black backdrop-filter backdrop-blur-md bg-opacity-50 z-50'>
            <div className='max-w-7xl mx-auto px-4 sm:px-6'>
                <div className='flex justify-between items-center py-6 md:justify-start md:space-x-10'>
                    <Link to={'/'} className='flex justify-start lg:w-0 lg:flex-1'>
                        <img className='h-8 w-auto sm:h-10' src={Logo} alt='My Logo' />
                    </Link>
                    <nav className='hidden md:flex space-x-10'>
                        {navLinks.map((da, index) =>
                            <React.Fragment key={index}>
                                {da.logged ? (
                                    userInfo ? (<Link className='text-base font-medium text-white hover:text-gray-300' to={da.url}>{da.display}</Link>) : ""
                                ) : (
                                    <Link className='text-base font-medium text-white hover:text-gray-300' to={da.url}>{da.display}</Link>
                                )}
                            </React.Fragment>
                        )}
                    </nav>
                    <div className='hidden md:flex items-center justify-end md:flex-1 lg:w-0'>
                        {userInfo ? (
                            <div className='relative inline-block text-left' ref={dropdownRef}>
                                <div>
                                    <button onClick={() => setOpen(!open)} className='hover:text-gray-300 text-white inline-flex justify-center w-full px-4 py-2 text-sm font-medium focus:rounded-md'>
                                        {userInfo.username}
                                        <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div onClick={() => setOpen(!open)} className={`${open ? "" : "hidden"} origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                    <div className='py-1'>
                                        {userInfo.isAdmin
                                            ? adminMenu.map((da, index) =>
                                                <Link key={index} to={da.url} className='font-medium hover:text-gray-300 text-white block px-4 py-2 text-sm'>{da.display}</Link>
                                            )
                                            : ""}
                                        {userMenu.map((da, index) =>
                                            <Link key={index} className='font-medium hover:text-gray-300 text-white block px-4 py-2 text-sm' to={da.url}>{da.display}</Link>
                                        )}
                                            <p onClick={logoutHandler} className='cursor-pointer font-medium hover:text-gray-300 text-white block px-4 py-2 text-sm'>Logout</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='relative inline-block text-left space-x-10'>
                                <Link className='text-base font-medium text-white hover:text-gray-300' to={parsed ? `/login?next=${parsed.query.next}` : '/login'}>Login</Link>
                                <Link className='text-base font-medium text-white hover:text-gray-300' to={parsed ? `/register?next=${parsed.query.next}` : '/register'}>Register</Link>
                            </div>
                        )}
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <button onClick={() => setMobileOpen(true)} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                        <span className="sr-only">Open menu</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                        </button>
                    </div>
                    <div onClick={() => setMobileOpen(false)} id="mobile" className={`${mobileOpen ? "" : "hidden"} md:hidden lg:hidden absolute top-0 inset-x-0 p-2 transition transform origin-top-right`}>
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black backdrop-filter backdrop-blur-md bg-opacity-95 text-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                            <div>
                                <img className="h-8 w-auto" src={Logo} alt="Logo" />
                            </div>
                            <div onClick={() => setMobileOpen(false)} className="-mr-2">
                                <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Close menu</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                                </button>
                            </div>
                            </div>
                            <div className="mt-6">
                            <nav className="grid space-y-1">
                                {navLinks.map((da, index) =>
                                    <React.Fragment key={index}>

                                            {da.logged ? (
                                                userInfo ? (<Link className='text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800' to={da.url}>{da.display}</Link>) : ""
                                            ) : (
                                                <Link className='text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800' to={da.url}>{da.display}</Link>
                                            )}

                                    </React.Fragment>
                                )}
                            </nav>
                            </div>
                        </div>
                            <div className="py-6 px-5 text-white">
                                <div className='grid space-y-1'>
                                {userInfo
                                    ? adminMenu.map((da, index) =>
                                        <Link key={index} to={da.url} className='text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800'>{da.display}</Link>
                                    )
                                    : ""}
                                    {userInfo
                                        ? (
                                            <div className='flex flex-col space-y-1'>
                                                {userMenu.map((da, index) =>
                                                    <Link key={index} className='text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800' to={da.url}>{da.display}</Link>
                                                )}
                                                <p onClick={logoutHandler} className='cursor-pointer text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800'>Logout</p>
                                            </div>
                                          )
                                        : (
                                            <div className={'flex flex-col space-y-1'}>
                                                <Link className='text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800' to={"/login"}>Login</Link>
                                                <Link className='text-base font-medium text-gray-300 p-3 flex items-right rounded-md hover:bg-gray-800' to={"/register"}>Register</Link>
                                            </div>
                                          )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header