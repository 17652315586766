import React from 'react';
import Layout from "../components/Layout/Layout";
import BlogPost from "../components/Updates/BlogPost";
import Blogs from '../components/Updates/Blogs.json'

const Updates = () => {
    return (
        <Layout title={'App Updates'}>
            <div className={'flex flex-col text-start'}>
                {Blogs.sort((a, b) => new Date(b.title) - new Date(a.title)).map((blog) =>
                    <BlogPost {...blog} />
                )}
            </div>
        </Layout>
    );
};

export default Updates;