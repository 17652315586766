import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import EditButton from '../../components/Layout/EditButton'
import ReminderWidget from '../../components/Dashboard/ReminderWidget'
import Layout from '../../components/Layout/Layout'
import { useNavigate } from 'react-router'
import EmptyReminderWidget from "../../components/Dashboard/EmptyReminderWidget";

const Reminders = () => {

  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
      if(!userInfo) {
          history('/login?next=/reminders');
      }
  }, [userInfo, history])

    const categoryGet = useSelector(state => state.categoryGet);
    const { length: categoryLength, error: categoryError } = categoryGet;

  return (
    <>
        <Layout title={"My Reminders"}>
            <div className='flex flex-col w-full flex-grow'>
                {
                    (categoryLength <= 0 || categoryError)
                        ? (<EmptyReminderWidget/>)
                        : (<ReminderWidget />)
                }
            </div>
        </Layout>
        <div className='block fixed right-5 bottom-5 z-50 text-white'>
            <EditButton/>
        </div>
    </>
  )
}

export default Reminders