import React from 'react'
import {ClipLoader} from 'react-spinners'

const Loading = ({absolute, bg, size, color, padding, rounded, opacity}) => {
  return (
    <div className={`flex justify-center items-center w-full h-full ${rounded ? rounded : ""} ${bg ? "" : "bg-black"} ${padding ? padding : "p-0"} ${opacity ? opacity : "bg-opacity-70"} z-50 ${absolute ? "absolute" : ""}`}>
        <ClipLoader color={color || `#ffffff`} size={size || 150} />
    </div>
  )
}

export default Loading